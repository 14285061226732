import PasswordInput from '@/components/passwordInput'
import useGetPasswordTypes from '@/modules/signup/queries/useGetPasswordTypes'
import useCreatePassword from '@/modules/startup/queries/useCreatePassword'
import useSignUpProspect from '@/modules/startup/queries/useSignUpProspect'
import { CheckCircle, RadioButtonUnchecked } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, FormControl, InputLabel, Modal, Typography } from '@mui/material'
import { green, grey } from '@mui/material/colors'
import { useEffect, useState } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

type PasswordInputs = {
  idType: string
  password: string
}

type CreatePasswordInputs = {
  idType: string
  password: string
}

export default function StartupPage() {
  const [searchParams] = useSearchParams()
  const uuid = searchParams.get('uuid') ?? ''
  const { t } = useTranslation()
  const [open, setOpen] = useState(true)
  const signUpProspect = useSignUpProspect()
  const createPassword = useCreatePassword()
  const passwordTypes = useGetPasswordTypes()
  const defaultPass = passwordTypes.data?.types.find(t => t.default)
  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
  } = useForm<PasswordInputs>()

  const [passwordRequirements, setPasswordRequirements] = useState({
    length: false,
    lowerCase: false,
    upperCase: false,
    number: false,
    specialChar: false,
  })

  const password = watch('password', '')

  const requirements = [
    {
      label: 'Al menos 8 caracteres',
      regex: /.{8,}/,
      key: 'length' as const,
    },
    {
      label: 'Al menos 1 letra minúscula (a-z)',
      regex: /[a-z]/,
      key: 'lowerCase' as const,
    },
    {
      label: 'Al menos 1 letra mayúscula (A-Z)',
      regex: /[A-Z]/,
      key: 'upperCase' as const,
    },
    {
      label: 'Al menos 1 número (0-9)',
      regex: /\d/,
      key: 'number' as const,
    },
    {
      label: 'Al menos un caracter especial (@$!%*?&)',
      regex: /[@$!%*?&]/,
      key: 'specialChar' as const,
    },
  ]

  useEffect(() => {
    signUpProspect.mutate({ uuid })
  }, [])

  useEffect(() => {
    if (createPassword.isSuccess) {
      setOpen(false)
    }
  }, [createPassword])

  useEffect(() => {
    const [length, lowerCase, upperCase, number, specialChar] = requirements
    setPasswordRequirements({
      length: length.regex.test(password),
      lowerCase: lowerCase.regex.test(password),
      upperCase: upperCase.regex.test(password),
      number: number.regex.test(password),
      specialChar: specialChar.regex.test(password),
    })
  }, [password])

  const handleOnSubmitCreatePassword: SubmitHandler<CreatePasswordInputs> = data => {
    createPassword.mutate({
      idType: defaultPass?.id.toString() ?? '2',
      password: data.password,
    })
  }

  const handleOnClickConfirmPassword = () => {
    handleSubmit(handleOnSubmitCreatePassword)()
  }

  // const handleRetry = () => {
  //   navigate('/')
  //   setOpen(false)
  // }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: 'absolute' as const,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: {
            xs: '90%',
            sm: '90%',
            md: 450,
            lg: 450,
          },
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: 2,
          outline: 'none',
          p: 4,
        }}
      >
        <Typography sx={{ mb: 6 }} id="modal-title" variant="h6" component="h2">
          Configura tu cuenta
        </Typography>
        <Box component="form" sx={{ display: 'flex', flexDirection: 'column' }}>
          <InputLabel sx={{ color: 'text.primary', fontWeight: 500, mb: 1 }}>
            Establecé una contraseña
          </InputLabel>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <FormControl>
              <PasswordInput
                error={!!errors.password}
                helperText={errors.password?.message}
                placeholder={'Ingresa tu contraseña'}
                {...register('password', {
                  required: {
                    value: true,
                    message: t('required', { ns: 'validation' }),
                  },
                  minLength: {
                    value: defaultPass?.min ?? 0,
                    message: t('min', {
                      ns: 'validation',
                      min: defaultPass?.min ?? 0,
                    }),
                  },
                  maxLength: {
                    value: defaultPass?.max ?? 255,
                    message: t('max', {
                      ns: 'validation',
                      max: defaultPass?.max ?? 255,
                    }),
                  },
                })}
              />
            </FormControl>
            <Box
              sx={{
                display: 'flex',
                padding: 2,
                flexDirection: 'column',
                borderWidth: 1,
                borderRadius: 2,
                border: 1,
                gap: 2,
                borderColor: 'divider',
                mb: 4,
              }}
            >
              {requirements.map(req => (
                <Box
                  key={req.key}
                  sx={{
                    display: 'flex',
                    gap: 1,
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 600,
                      color: passwordRequirements[req.key] ? green[400] : grey[400],
                    }}
                  >
                    {req.label}
                  </Typography>
                  <Box>
                    {passwordRequirements[req.key] ? (
                      <CheckCircle style={{ color: green[400] }} />
                    ) : (
                      <RadioButtonUnchecked style={{ color: grey[400] }} />
                    )}
                  </Box>
                </Box>
              ))}
            </Box>
            <LoadingButton
              type="submit"
              variant="contained"
              fullWidth
              loading={createPassword.isPending}
              disabled={Object.values(passwordRequirements).some(requirement => !requirement)}
              onClick={handleOnClickConfirmPassword}
            >
              Confirmar contraseña
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}
