/**
 * Converts a color code to an RGBA color with the specified opacity.
 *
 * @param color - The color code to convert (e.g., "#FF0000" for red).
 * @param opacity - The opacity value between 0 and 1 (default: 0.6).
 * @returns The RGBA color string with the specified opacity.
 */
export const convertColorToOpacity = (color: string, opacity = 0.6): string =>
  `rgba(${Number.parseInt(color.slice(1, 3), 16)}, ${Number.parseInt(
    color.slice(3, 5),
    16,
  )}, ${Number.parseInt(color.slice(5, 7), 16)}, ${opacity})`

/**
 * Generates a random color based on the given text.
 * @param text - The label used to generate the color.
 * @returns A randomly generated color.
 */
export const generateRandomColor = (text: string): string => {
  const colors = [
    '#42B3FF',
    '#50D174',
    '#ff9800',
    '#e91e63',
    '#9e9e9e',
    '#3f51b5',
    '#f44336',
    '#00bcd4',
    '#ffc107',
    '#8bc34a',
  ]

  let hash = 0
  for (let i = 0; i < text.length; i++) {
    hash = text.charCodeAt(i) + ((hash << 5) - hash)
  }

  const index = Math.abs(hash % colors.length)
  return colors[index]
}
