/**
 * Clamps the given text to a specified character limit and appends an ellipsis if necessary.
 *
 * @param str - The input string to be clamped.
 * @param limit - The maximum number of characters allowed in the clamped string.
 * @param elipsis - The ellipsis string to be appended at the end of the clamped string. Default is '...'.
 * @returns The clamped string.
 */
export const clamp = (str: string, limit: number, elipsis = '...') =>
  str.length > limit ? `${str.substr(0, limit - elipsis.length).trim()}${elipsis}` : str

/**
 * Capitalizes the first letter of a string. Aka title case.
 *
 * @param str - The input string.
 * @returns The input string with the first letter capitalized.
 */
export const capFirst = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

/**
 * Capitalizes each word in a string.
 *
 * @param str - The string to capitalize.
 * @returns The capitalized string.
 */
export const capitalize = (str: string) => {
  return str
    .split(' ')
    .map(c => c.charAt(0).toUpperCase() + c.slice(1))
    .join(' ')
}

/**
 * Removes leading and trailing spaces from a string.
 *
 * @param str - The string to trim.
 * @returns The trimmed string.
 */
export const trim = (str: string) => {
  return str
    .split(' ')
    .filter(c => c !== '')
    .join(' ')
}
