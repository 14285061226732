import Chip from '@/components/chip'
import { useApp } from '@/modules/app/state/useApp'
import { capFirst, clamp } from '@/utils/text'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Box, Card, IconButton, Menu, MenuItem, Skeleton, Typography } from '@mui/material'
import { common } from '@mui/material/colors'
import { useEffect, useState } from 'react'
import { useIntersectionObserver } from 'usehooks-ts'
import useInfiniteGetOrganizations from '../queries/useInfiniteGetOrganizations'

interface InstitutionsCardsProps {
  searchQuery: string
  refresh: boolean
}

export default function InstitutionsCards({ searchQuery, refresh }: InstitutionsCardsProps) {
  const rowsPerPage = 50
  const { isDarkMode } = useApp()
  const organizations = useInfiniteGetOrganizations(refresh, rowsPerPage, searchQuery)
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null)
  const { isIntersecting, ref } = useIntersectionObserver({
    threshold: 0.5,
  })

  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(e.currentTarget)
  }

  const handleOnClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    if (isIntersecting && organizations.hasNextPage && !organizations.isFetching) {
      organizations.fetchNextPage()
    }
  }, [isIntersecting])

  if (
    !organizations.isFetching &&
    organizations.data?.pages.at(0)?.data.description.organizations.length === 0
  ) {
    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h6" sx={{ alignSelf: 'center' }}>
          No se han encontrado resultados.
        </Typography>
      </Box>
    )
  }

  if (organizations.isFetching && !organizations.isFetchingNextPage) {
    return (
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            sm: 'repeat(auto-fill, minmax(auto, 1fr))',
            md: 'repeat(auto-fill, minmax(350px, 1fr))',
            lg: 'repeat(auto-fill, minmax(350px, 1fr))',
          },
          gap: {
            xs: 2,
            sm: 2,
            md: 4,
            lg: 4,
          },
        }}
      >
        {[...Array(6)].map(() => (
          <Card
            key={crypto.randomUUID()}
            elevation={1}
            sx={{
              p: 4,
              height: 220,
              borderRadius: 2,
              display: 'flex',
              position: 'relative',
              flexDirection: 'column',
              justifyContent: 'space-between',
              border: 1,
              borderColor: 'divider',
              bgcolor: 'background.paper',
            }}
          >
            <Box>
              <Skeleton sx={{ mb: 2, borderRadius: 2 }} variant="text" width="10%" height={20} />
              <Skeleton sx={{ borderRadius: 2 }} variant="text" width="80%" height={20} />
              <Skeleton sx={{ borderRadius: 2 }} variant="text" width="60%" height={20} />
            </Box>
          </Card>
        ))}
      </Box>
    )
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: {
          sm: 'repeat(auto-fill, minmax(auto, 1fr))',
          md: 'repeat(auto-fill, minmax(350px, 1fr))',
          lg: 'repeat(auto-fill, minmax(350px, 1fr))',
        },
        gap: {
          xs: 2,
          sm: 2,
          md: 4,
          lg: 4,
        },
      }}
    >
      {organizations.data?.pages.map(organization => {
        return organization.data.description.organizations.map(org => (
          <Card
            key={org.id}
            ref={ref}
            elevation={1}
            sx={{
              p: 2,
              height: 220,
              borderRadius: 2,
              border: 1,
              display: 'grid',
              cursor: 'pointer',
              borderColor: 'divider',
              bgcolor: 'background.paper',
              gridTemplateColumns: '1fr 40px',
              '&:hover': {
                bgcolor: 'background.default',
              },
            }}
          >
            <Box
              sx={{
                p: 2,
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography mb={2} fontWeight={600} fontSize={16} color="text.primary">
                  ID: {org.id}
                </Typography>
                <Typography fontSize={16} fontWeight={600}>
                  {clamp(org.name, 52)}
                </Typography>
                <Typography fontSize={14} mb={4} color="text.secondary">
                  {org.address}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {org.stages
                  .slice(0, 4)
                  .map((s, index) =>
                    index < 3 ? (
                      <Chip key={s.id} aria-label={s.name} label={capFirst(s.name)} />
                    ) : (
                      <Chip
                        key="total"
                        variant={isDarkMode ? 'outlined' : 'filled'}
                        aria-label={'total'}
                        bgColor={common.white}
                        label={`+${org.stages.length - 3}`}
                      />
                    ),
                  )}
              </Box>
            </Box>
            <Box sx={{ pt: 1, pb: 1, display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton
                aria-label="opciones"
                onClick={handleOnClick}
                sx={{ width: 34, height: 34 }}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                elevation={1}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleOnClose}
              >
                <MenuItem aria-label="editar" onClick={handleOnClose}>
                  Editar
                </MenuItem>
                <MenuItem aria-label="eliminar" onClick={handleOnClose}>
                  Eliminar
                </MenuItem>
              </Menu>
            </Box>
          </Card>
        ))
      })}
      {organizations.isFetchingNextPage ? (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Typography sx={{ color: 'primary.main', alignSelf: 'center', fontWeight: 600 }}>
            Cargando
          </Typography>
        </Box>
      ) : null}
    </Box>
  )
}
