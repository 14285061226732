import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { InputAdornment, TextField, type TextFieldProps, alpha, styled } from '@mui/material'
import { common } from '@mui/material/colors'

export const SearchTextField = styled(TextField)(() => ({
  width: 280,
  border: 0,
  borderRadius: '8px',
  background: alpha(common.white, 0.1),
  '&:hover': {
    background: alpha(common.white, 0.2),
  },
  '& .MuiInputBase-input': {
    color: common.white,
  },
  '& .MuiInputBase-root': {
    color: common.white,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
  '& .MuiInputAdornment-root svg': {
    color: common.white,
  },
}))

export default function GlobalSearch(props: TextFieldProps) {
  return (
    <SearchTextField
      size="small"
      aria-label="Buscar"
      placeholder="Buscar en la plataforma..."
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchOutlinedIcon />
          </InputAdornment>
        ),
      }}
      {...props}
    />
  )
}
