import { useAuth } from '@/modules/auth/state/useAuth'
import { lms } from '@/services/client'
import type { ClientError } from '@educabot/cloud-client'
import type { LoginV4 } from '@educabot/cloud-client/lib/lms/login/loginWithPassword'
import { useMutation } from '@tanstack/react-query'
import type { AxiosResponse } from 'axios'

export default function useLogin() {
  const { login } = useAuth()
  const query = useMutation<AxiosResponse<LoginV4.LoginResponse>, ClientError, LoginV4.LoginData>({
    mutationFn: data => {
      return lms.login.loginV4(data)
    },
    onSuccess: ({ data }) => {
      login(data.description.token)
    },
  })

  return query
}
