import { ArrowBack, Close } from '@mui/icons-material'
import { IconButton, type IconButtonProps } from '@mui/material'

export interface ModalButtonProps extends IconButtonProps {
  kind?: 'close' | 'back'
  position?: 'left' | 'right'
}

export function ModalButton({
  kind = 'close',
  position = 'left',
  children,
  ...props
}: ModalButtonProps) {
  return (
    <IconButton
      sx={{
        position: 'absolute',
        [position]: t => t.spacing(2.8),
      }}
      {...props}
    >
      {children || (kind === 'back' ? <ArrowBack /> : <Close />)}
    </IconButton>
  )
}
