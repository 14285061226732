import env from '@/config/env'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'

export interface AuthButtonProps {
  type: 'google' | 'microsoft' | 'facebook'
}

export default function AuthButton({ type }: AuthButtonProps) {
  const image = {
    google: `${env.ASSETS_CDN_URL}/images/google_logo.svg`,
    facebook: `${env.ASSETS_CDN_URL}/images/facebook_logo.svg`,
    microsoft: `${env.ASSETS_CDN_URL}/images/microsoft_logo.svg`,
  }[type]

  const label = {
    google: 'Google',
    facebook: 'Facebook',
    microsoft: 'Microsoft',
  }[type]

  return (
    <Box
      sx={{
        width: 50,
        cursor: 'pointer',
        alignSelf: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          width: 50,
          height: 50,
          mb: 1.2,
          border: 1,
          display: 'flex',
          borderRadius: '100%',
          justifyContent: 'center',
          borderColor: 'divider',
        }}
      >
        <img style={{ width: 24, height: 24, alignSelf: 'center' }} src={image} alt={label} />
      </Box>
      <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}>
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 600,
            alignSelf: 'center',
            color: 'text.primary',
            textTransform: 'capitalize',
          }}
        >
          {label}
        </Typography>
      </Box>
    </Box>
  )
}
