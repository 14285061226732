import { useAuth } from '@/modules/auth/state/useAuth'
import { client } from '@/services/client'
import type { ClientError } from '@educabot/cloud-client'
import type { SignUpV1 } from '@educabot/cloud-client/lib/lms/prospect/signup'
import { useMutation } from '@tanstack/react-query'
import type { AxiosResponse } from 'axios'

export interface SignUpProspectVars {
  uuid: string
}

export default function useSignUpProspect() {
  const { login } = useAuth()

  const query = useMutation<
    AxiosResponse<SignUpV1.SignUpResponse>,
    ClientError,
    SignUpProspectVars
  >({
    mutationFn: d => {
      return client.lms.prospect.signup({ uuid: d.uuid })
    },
    onSuccess: ({ data }) => {
      login(data.description.token)
    },
  })

  return query
}
