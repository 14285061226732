import { Navigate } from 'react-router-dom'
import { useAuth } from '../state/useAuth'

export interface ProtectedProps {
  redirect?: string
  children: React.ReactNode
}

export default function Protected({ redirect = '/login/id', children }: ProtectedProps) {
  const { isAuthenticated } = useAuth()
  return isAuthenticated ? children : <Navigate to={redirect} />
}
