import { capitalize } from '@/utils/text'
import type { MasterSerie } from '@educabot/cloud-client/lib/org/master-series/get/get'
import { DeleteOutlineOutlined } from '@mui/icons-material'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import {
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import type { SelectChangeEvent } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Series {
  [stage: string]: MasterSerie[]
}

interface StepFourProps {
  stages: string[]
  selectors: Series
  addSelectors: (value: Series) => void
  masterSeries: MasterSerie[]
}

export default function StepFour({
  selectors: storedSelectors,
  stages,
  masterSeries,
  addSelectors,
}: StepFourProps) {
  const { t } = useTranslation()

  // group master series by stage
  const seriesByStage = masterSeries.reduce(
    (acc, item) => {
      acc[item.stage] = [...(acc[item.stage] || []), item]
      return acc
    },
    {} as { [key: string]: MasterSerie[] },
  )

  const [selectors, setSelectors] = useState<Series>(() =>
    stages.reduce(
      (acc, stage) => ({
        // biome-ignore lint/performance/noAccumulatingSpread: <explanation>
        ...acc,
        [stage]: storedSelectors?.[stage]?.length
          ? storedSelectors[stage]
          : seriesByStage[stage]?.length
            ? [seriesByStage[stage][0]]
            : [],
      }),
      {},
    ),
  )

  // store initial selectors in parent component
  useEffect(() => {
    addSelectors(selectors)
  }, [addSelectors])

  const updateSelectors = (stage: string, newSelectors: MasterSerie[]) => {
    setSelectors(s => {
      const updated = { ...s, [stage]: newSelectors }
      addSelectors(updated)
      return updated
    })
  }

  const handleOnChange = (stage: string, index: number) => (e: SelectChangeEvent<string>) => {
    const selectedId = e.target.value
    const selectedSerie = seriesByStage[stage].find(serie => serie.id.toString() === selectedId)
    if (!selectedSerie) return

    if (selectedId === 'remove' && index !== 0) {
      handleRemoveSelector(stage, index)()
      return
    }

    const newSelectors = [...selectors[stage]]
    newSelectors[index] = selectedSerie
    updateSelectors(stage, newSelectors)
  }

  const handleAddSelector = (stage: string) => () => {
    const firstSerie = seriesByStage[stage][0]
    const newSelectors = [...selectors[stage], firstSerie || { id: '', name: '' }]
    updateSelectors(stage, newSelectors)
  }

  const handleRemoveSelector = (stage: string, index: number) => () => {
    const newSelectors = [...selectors[stage]]
    newSelectors.splice(index, 1)
    updateSelectors(stage, newSelectors)
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box mb={4}>
        <Typography mb={1} variant="h5">
          Seleccioná los niveles de educación que tiene tu institución.
        </Typography>
        <Typography fontSize={t => t.typography.pxToRem(16)} color="text.secondary">
          Para cada uno de los niveles seleccionados en el paso anterior, indicá los tipos de
          niveles que posee tu institución.
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: '1fr',
            sm: 'repeat(2, 1fr)',
            md: 'repeat(3, 1fr)',
            lg: 'repeat(auto, 1fr)',
          },
          gap: 4,
        }}
      >
        {stages.map(stage => {
          return (
            <Box
              key={stage}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                mb: 2,
                position: 'relative',
                '&:hover .add-level-button': {
                  opacity: 1,
                },
              }}
            >
              <InputLabel
                sx={{
                  mb: 1,
                  fontWeight: 600,
                  color: 'primary.main',
                  fontSize: t => t.typography.pxToRem(16),
                }}
              >
                {capitalize(t(`levels.${stage}`, { ns: 'glossary' }))}
              </InputLabel>
              {selectors[stage].map((selectedSerie, index) => (
                // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                <Box key={index} sx={{ mb: 2, position: 'relative' }}>
                  <FormControl fullWidth>
                    <Select
                      value={selectedSerie.id.toString()}
                      onChange={handleOnChange(stage, index)}
                    >
                      {seriesByStage[stage]?.map(serie => (
                        <MenuItem key={serie.id} value={serie.id.toString()}>
                          {serie.name}
                        </MenuItem>
                      ))}

                      {index !== 0 && <Divider />}
                      {index !== 0 && (
                        <MenuItem value="remove" onClick={handleRemoveSelector(stage, index)}>
                          <DeleteOutlineOutlined sx={{ fontSize: 22, mr: 1 }} />
                          Remover
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Box>
              ))}
              <Button
                variant="text"
                sx={{ opacity: 0, alignSelf: 'flex-end' }}
                className="add-level-button"
                onClick={handleAddSelector(stage)}
                startIcon={<AddCircleOutlineOutlinedIcon />}
              >
                Agregar nivel
              </Button>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}
