import { getLocale } from '@/locales/utils'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next as I18nNext } from 'react-i18next'
import 'dayjs/locale/es'
import 'dayjs/locale/en'
import dayjs from 'dayjs'

import en from '@/locales/namespaces/en'
import es from '@/locales/namespaces/es'

export const i18next = i18n.use(I18nNext).use(LanguageDetector)

export function init() {
  const locale = getLocale() ?? 'es'
  dayjs.locale(locale)

  return i18next.init({
    fallbackLng: locale,
    compatibilityJSON: 'v4',
    ns: ['translation', 'common', 'glossary', 'validation'],
    interpolation: {
      escapeValue: false,
    },
    resources: {
      es,
      en,
    },
  })
}
