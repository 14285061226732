import { Box, Button, LinearProgress, Typography, useTheme } from '@mui/material'
import { useDebounceValue } from 'usehooks-ts'
import useUserInfo from '../user/queries/useUserInfo'

export function Wrapper({ children }: React.PropsWithChildren) {
  const theme = useTheme()
  const { isPending, isSuccess, isError, refetch } = useUserInfo()
  const [loading] = useDebounceValue(isPending, 1000)

  if (loading) {
    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          bgcolor: 'background.default',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            width: 280,
            height: 'fit-content',
            display: 'flex',
            alignSelf: 'center',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'column',
            mb: 12,
          }}
        >
          <Typography fontSize={18} mb={2.5}>
            Cargando algunos datos
          </Typography>
          <LinearProgress
            color={theme.palette.mode === 'dark' ? 'secondary' : 'primary'}
            sx={{ width: '100%', height: 12, borderRadius: 2 }}
          />
        </Box>
      </Box>
    )
  }

  if (isError) {
    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          bgcolor: 'background.default',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography fontSize={18} mb={2.5} color="text.primary">
          Ocurrió un error al cargar los datos.
        </Typography>
        <Button size="large" variant="contained" color="primary" onClick={() => refetch()}>
          Reintentar
        </Button>
      </Box>
    )
  }

  return isSuccess ? children : null
}
