import GoogleButton from '@/modules/auth/login/components/googleButton'
import MicrosoftButton from '@/modules/auth/login/components/microsoftButton'
import type { PasswordInputs } from '@/modules/auth/login/components/password'
import Password from '@/modules/auth/login/components/password'
import useLogin from '@/modules/auth/login/queries/useLogin'
import { capFirst } from '@/utils/text'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Divider, IconButton, Typography } from '@mui/material'
import { useEffect } from 'react'
import type { SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

type PasswordPageParams = {
  id: string
  idType: string
}

export default function LoginPasswordPage() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const loginQuery = useLogin()
  const form = useForm<PasswordInputs>()
  const { id = '', idType = '0' } = useParams<PasswordPageParams>()

  useEffect(() => {
    if (loginQuery.isSuccess) {
      navigate('/home/institutions')
    }

    if (loginQuery.isError) {
      form.setError('password', {
        message: loginQuery.error.message,
      })
    }
  }, [loginQuery.status])

  const handleOnSubmitLogin: SubmitHandler<PasswordInputs> = data => {
    loginQuery.mutate({
      id: id,
      idType: idType,
      password: data.password,
    })
  }

  const handleOnClickBack = () => {
    form.reset()
    navigate(`/login/id/${id}/${idType}`)
  }

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      form.handleSubmit(handleOnSubmitLogin)()
    }
  }

  return (
    <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box sx={{ width: 40, height: 40, display: 'flex' }} onClick={handleOnClickBack}>
        <IconButton>
          <ArrowBackIcon />
        </IconButton>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography color="text.primary" fontWeight={600} variant="h4">
          {capFirst(t('almostReady', { ns: 'common' }))},
        </Typography>
        <Typography color="text.secondary" fontWeight={400} variant="h6">
          {t('login.enterYourPasswordToContinue')}
        </Typography>
      </Box>
      <Password
        errors={form.formState.errors}
        register={form.register}
        onKeyDown={handleOnKeyDown}
      />
      <LoadingButton
        variant="contained"
        size={'large'}
        sx={{ textTransform: 'capitalize' }}
        loading={loginQuery.isPending}
        onClick={form.handleSubmit(handleOnSubmitLogin)}
      >
        {t('enter', { ns: 'common' })}
      </LoadingButton>
      <Divider sx={{ m: 2 }} />
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
        }}
      >
        <GoogleButton />
        <MicrosoftButton />
      </Box>
    </Box>
  )
}
