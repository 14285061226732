import { capitalize } from '@/utils/text'
import type { MasterSerie } from '@educabot/cloud-client/lib/org/master-series/get/get'
import BusinessIcon from '@mui/icons-material/Business'
import SchoolIcon from '@mui/icons-material/School'
import { Box, Divider, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import type { AcademicPeriodSelector } from '../../types/institution'

interface Series {
  [stage: string]: MasterSerie[]
}

interface StepSixProps {
  idj: string
  name: string
  address: string
  seriesSelectors: Series
  academicPeriodSelectors: AcademicPeriodSelector
}

export default function StepSix({
  idj,
  name,
  address,
  seriesSelectors,
  academicPeriodSelectors,
}: StepSixProps) {
  console.log(academicPeriodSelectors)
  const { t } = useTranslation()
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', mb: 6, justifyContent: 'space-between' }}>
      <Box mb={2}>
        <Typography variant="h5" gutterBottom>
          Revisión final de datos
        </Typography>
        <Typography fontSize={16} color="text.secondary">
          Por favor, asegúrate de que toda la información ingresada para la creación de la nueva
          institución sea correcta.
        </Typography>
      </Box>
      <Divider sx={{ my: 6 }} />
      <Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 1 }}>
            <BusinessIcon sx={{ mr: 1, color: 'primary.main' }} />
            <Typography variant="h6">Información de la Institución</Typography>
          </Box>
          <Typography variant="subtitle1" mb={4} color="text.secondary" fontWeight="medium">
            Información básica
          </Typography>
        </Box>
        <Box ml={2} gap={2} sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box display="flex" flexDirection="row" gap={2}>
            <Typography fontWeight="bold">Identificador:</Typography>
            <Typography>{idj}</Typography>
          </Box>
          <Box display="flex" flexDirection="row" gap={2}>
            <Typography fontWeight="bold">Nombre legal:</Typography>
            <Typography>{name}</Typography>
          </Box>
          <Box display="flex" flexDirection="row" gap={2}>
            <Typography fontWeight="bold">Dirección:</Typography>
            <Typography>{address}</Typography>
          </Box>
        </Box>
      </Box>
      <Divider sx={{ my: 6 }} />
      <Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 1 }}>
            <SchoolIcon sx={{ mr: 1, color: 'primary.main' }} />
            <Typography variant="h6">Niveles de Educación</Typography>
          </Box>
          <Typography variant="subtitle1" mb={4} color="text.secondary" fontWeight="medium">
            Niveles de educación y sus ciclos lectivos
          </Typography>
        </Box>
        <Box
          sx={{
            ml: 2,
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(180px, auto))',
            gap: 4,
          }}
        >
          {Object.keys(seriesSelectors).map(stage => (
            <Box key={stage} display="flex" flexDirection="column" gap={2}>
              <Typography fontWeight="bold">
                {capitalize(t(`levels.${stage}`, { ns: 'glossary' }))}
              </Typography>
              {seriesSelectors[stage].map((serie, index) => (
                // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                <Typography key={index} color="text.secondary">
                  {serie.name}
                </Typography>
              ))}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
}
