import { client } from '@/services/client'
import type { ClientError } from '@educabot/cloud-client'
import type { CreateV2 } from '@educabot/cloud-client/lib/lms/user/password/create'
import { useMutation } from '@tanstack/react-query'
import type { AxiosResponse } from 'axios'

export default function useCreatePassword() {
  const query = useMutation<AxiosResponse<void>, ClientError, CreateV2.CreatePasswordData>({
    mutationFn: d => {
      return client.lms.user.password.createV2({
        idType: d.idType,
        password: d.password,
      })
    },
  })

  return query
}
