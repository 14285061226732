import { Wrapper } from '@/modules/app/wrapper'
import Protected from '@/modules/auth/guard/protected'
import UserPage from '@/pages/user'
import SettingsPage from '@/pages/user/settings'
import { Suspense } from 'react'
import { Navigate, Outlet, type RouteObject } from 'react-router-dom'

const routes: RouteObject = {
  path: 'user',
  element: (
    <Protected>
      <Suspense>
        <Wrapper>
          <Outlet />
        </Wrapper>
      </Suspense>
    </Protected>
  ),
  children: [
    {
      path: '*',
      element: <UserPage />,
      children: [
        {
          path: 'settings',
          element: <SettingsPage />,
        },
        {
          path: '*',
          element: <Navigate to="" replace />,
        },
      ],
    },
    {
      path: '*',
      element: <Navigate to="" replace />,
    },
  ],
}

export default routes
