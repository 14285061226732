import type { Type as IndentificationType } from '@educabot/cloud-client/lib/lms/login/getIdTypes/getIdTypes'
import type { SelectChangeEvent } from '@mui/material'
import { Box, TextField, Typography } from '@mui/material'
import { FormControl, MenuItem, Select } from '@mui/material'
import type { FieldErrors, UseFormRegister } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export type IdentificationInputs = {
  id: string
  idType: string
}

export interface IdentificationProps {
  idType: IndentificationType
  docTypes: IndentificationType[]
  errors: FieldErrors<IdentificationInputs>
  register: UseFormRegister<IdentificationInputs>
  onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void
  onChangeType: (e: SelectChangeEvent<number>) => void
}

export default function Identification({
  idType,
  docTypes,
  errors,
  register,
  onKeyDown,
  onChangeType,
}: IdentificationProps) {
  const { t } = useTranslation()

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: 'divider',
          borderRadius: 2,
          overflow: 'hidden',
          '&:focus': {
            borderColor: 'red',
          },
          '&:hover': {
            borderColor: 'primary.main',
          },
        }}
      >
        <FormControl>
          <Select
            displayEmpty
            value={idType.id}
            error={!!errors.id}
            renderValue={() => (
              <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <img
                  style={{ width: 20, height: 20 }}
                  src={idType?.country?.flag || idType.icon}
                  alt="Country flag"
                />
                <Typography fontWeight={600} textTransform={'capitalize'}>
                  {idType?.name}
                </Typography>
              </Box>
            )}
            {...register('idType', {
              required: true,
              onChange: onChangeType,
            })}
            sx={{
              border: 'none',
              boxShadow: '12px 0px 1px -12px #a3a3a3',
              '& .MuiSelect-select': {
                borderRight: 'none',
                border: 'none',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                marginBottom: '1px',
                borderRight: 'none',
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                border: 'none',
              },
            }}
          >
            {docTypes.map(type => (
              <MenuItem key={crypto.randomUUID()} value={type.id} sx={{ gap: 2 }}>
                <img
                  style={{ width: 20, height: 20 }}
                  src={type.country?.flag || type.icon}
                  alt="Country flag"
                />
                <Typography fontWeight={500} textTransform={'capitalize'}>
                  {type.name}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ display: 'flex', flex: 1 }}>
          <TextField
            required
            variant="outlined"
            placeholder={t('login.enterYourId', { id: idType.name })}
            error={!!errors.id}
            onKeyDown={onKeyDown}
            {...register('id', {
              required: {
                value: true,
                message: t('login.idRequired'),
              },
              minLength: {
                value: idType.min,
                message: t('login.idMinLength', { min: idType.min }),
              },
              maxLength: {
                value: idType.max,
                message: t('login.idMaxLength', { max: idType.max }),
              },
            })}
            sx={{
              bgcolor: t => (t.palette.mode === 'light' ? 'background.default' : ''),
              '& .MuiOutlinedInput-root': {
                border: 'none',
                borderLeft: 'none',
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
                borderLeft: 'none',
                borderWidth: 1,
              },
            }}
          />
        </FormControl>
      </Box>
      <Typography mb={0} mt={1} mx={1} color="error" variant="body2">
        {errors.id?.message}
      </Typography>
    </Box>
  )
}
