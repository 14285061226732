import env from '@/config/env'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

export type CountryCode = 'AR'

export type CountryCodeResponse = {
  country: CountryCode
}

export default function useGetCountryCode() {
  const query = useQuery<CountryCodeResponse>({
    queryKey: ['get_country_code'],
    queryFn: () => {
      return axios.get(env.CLOUDFLARE_WORKER_COUNTRY_DETECTOR_URL).then(r => r.data)
    },
  })

  return query
}
