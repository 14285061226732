import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { InputAdornment, TextField } from '@mui/material'
import type { TextFieldProps } from '@mui/material/TextField'
import React, { useState } from 'react'

interface PasswordInputProps extends Omit<TextFieldProps, 'variant'> {
  value?: string
}

const PasswordInput = React.forwardRef(
  (props: PasswordInputProps, ref: React.Ref<HTMLDivElement>) => {
    const [visible, setVisible] = useState(false)

    const handleToggleVisibility = () => {
      setVisible(p => !p)
    }

    return (
      <TextField
        ref={ref}
        variant="outlined"
        type={visible ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {visible ? (
                <VisibilityIcon
                  sx={{ color: t => t.palette.action.active, cursor: 'pointer' }}
                  onClick={handleToggleVisibility}
                />
              ) : (
                <VisibilityOffIcon
                  sx={{ color: t => t.palette.action.active, cursor: 'pointer' }}
                  onClick={handleToggleVisibility}
                />
              )}
            </InputAdornment>
          ),
        }}
        {...props}
      />
    )
  },
)

export default PasswordInput
