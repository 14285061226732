import { useMsal } from '@azure/msal-react'
import { Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { request } from '../../providers/microsoft/msal'

export default function MicrosoftButton() {
  const { t } = useTranslation()
  const { instance } = useMsal()

  const handleOnClick = () => {
    instance.loginRedirect(request)
  }

  return (
    <Button
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        p: 2,
        gap: 2,
        border: 1,
        borderColor: '#1a73e8',
        borderRadius: 2,
        justifyContent: 'space-between',
        bgcolor: '#1a73e8',
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
          bgcolor: '#1263cd',
          borderColor: '#1263cd',
        },
      }}
      onClick={handleOnClick}
    >
      <Typography color={'common.white'} fontWeight={600} fontSize={13} textTransform={'none'}>
        {t('login.continueWith', { agent: 'Microsoft' })}
      </Typography>
      <img
        style={{ width: 24, height: 24, alignSelf: 'center' }}
        src="https://storage.googleapis.com/educabot-aec-cdn-1/assets/microsoft_logo.png"
        alt="Microsoft logo"
      />
    </Button>
  )
}
