import Scrollbar from '@/components/scrollbar'
import { Box } from '@mui/material'
import { Outlet } from 'react-router-dom'
import SidePanelLayout, { type SidePanelProps } from './sidePanel'

export interface ContentLayoutProps {
  sidePanel?: SidePanelProps
}

export default function ContentLayout({ sidePanel }: ContentLayoutProps) {
  return (
    <Box
      sx={t => ({
        width: '100%',
        height: '100%',
        display: 'grid',
        overflow: 'auto',
        gridTemplateColumns: 'auto 1fr',
        [t.breakpoints.down('md')]: {
          gridTemplateColumns: '1fr',
        },
      })}
    >
      <SidePanelLayout {...sidePanel} />
      <Scrollbar>
        <Outlet />
      </Scrollbar>
    </Box>
  )
}
