import Content from '@/layouts/content'
import Header from '@/layouts/header'
import Wrapper from '@/layouts/wrapper'

export default function HomePage() {
  return (
    <Wrapper>
      <Header />
      <Content />
    </Wrapper>
  )
}
