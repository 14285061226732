import { org } from '@/services/client'
import type { ClientError } from '@educabot/cloud-client'
import type { GetV1 } from '@educabot/cloud-client/lib/org/master-series/get'
import { useMutation } from '@tanstack/react-query'
import type { AxiosResponse } from 'axios'

export interface GetMasterSeriesVars {
  geoType?: string
  geoValue?: string
  limit?: number
  offset?: number
  stage?: string
}

export default function useGetMasterSeries() {
  const query = useMutation<
    AxiosResponse<GetV1.GetMasterSeriesResponse>,
    ClientError,
    GetMasterSeriesVars
  >({
    mutationFn: d => org.masterSeries.get(d),
  })

  return query
}
