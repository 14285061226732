import env from '@/config/env'
import { type Configuration, type PopupRequest, PublicClientApplication } from '@azure/msal-browser'

const config: Configuration = {
  auth: {
    clientId: env.MICROSOFT_AUTH_API_CLIENT_ID,
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: env.MICROSOFT_AUTH_API_REDIRECT_URI,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
}

export const msalInstance = new PublicClientApplication(config)

export const request: PopupRequest = {
  scopes: ['User.Read'],
  prompt: 'select_account',
}
