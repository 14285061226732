import { Skeleton, TableCell, TableRow } from '@mui/material'

interface SkeletonTableRowsProps {
  rowsPerPage: number
  cells: number
}

/**
 * Renders skeleton table rows with specified number of cells per row.
 *
 * @param {number} rowsPerPage - The number of rows to render.
 * @param {number} cells - The number of cells per row.
 */
export default function SkeletonTableRows({ rowsPerPage, cells }: SkeletonTableRowsProps) {
  const renderCells = (numCells: number) => {
    return Array.from({ length: numCells }).map(() => (
      <TableCell key={crypto.randomUUID()}>
        <Skeleton />
      </TableCell>
    ))
  }

  return Array.from({ length: rowsPerPage }).map(() => (
    <TableRow key={crypto.randomUUID()}>{renderCells(cells)}</TableRow>
  ))
}
