import { Navigate } from 'react-router-dom'
import { useAuth } from '../state/useAuth'

export interface AuthenticatedProps {
  redirect: string
  children?: React.ReactNode
}

export default function Authenticated({ redirect, children }: AuthenticatedProps) {
  const { isAuthenticated } = useAuth()
  if (isAuthenticated) return <Navigate to={redirect} replace />
  return children
}
