export type FullName = {
  name: string
  lastName: string
}

/**
 * Formats the full name by joining the last name and the name with a space.
 *
 * @param {FullName} fullName - The object containing the name and last name.
 * @returns {string} The formatted full name.
 */
export default function formatFullName({ name, lastName }: FullName) {
  return [lastName, name].join(' ')
}
