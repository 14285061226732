import { lms } from '@/services/client'
import type { ClientError } from '@educabot/cloud-client'
import type { GetV1 } from '@educabot/cloud-client/lib/lms/organizations/get'
import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query'
import type { AxiosResponse } from 'axios'

export default function useInfiniteGetOrganizations(
  refresh: boolean,
  rowsPerPage: number,
  searchQuery: string,
) {
  const query = useInfiniteQuery<AxiosResponse<GetV1.GetOrganizationsResponse>, ClientError>({
    queryKey: ['get_organizations_infinite', refresh, searchQuery],
    queryFn: ({ pageParam = 0 }) => {
      return lms.organizations.get({
        offset: pageParam as number,
        limit: rowsPerPage,
        search: searchQuery,
      })
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.data.description.organizations.length === 0) return undefined
      if (lastPage.data.description.total <= pages.length * rowsPerPage) return undefined
      return pages.length * rowsPerPage
    },
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })

  return query
}
