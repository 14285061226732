import { CssBaseline, ThemeProvider } from '@mui/material'
import { RouterProvider } from 'react-router-dom'
import router from './app/router/router'
import { useApp } from './modules/app/state/useApp'
import { darkTheme, lightTheme } from './theme'

export default function App() {
  const { isDarkMode } = useApp()
  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      <RouterProvider router={router} />
    </ThemeProvider>
  )
}
