import env from '@/config/env'
import useLoginWithGoogle from '@/modules/auth/login/queries/useLoginWithGoogle'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

export default function GoogleAuth() {
  const location = useLocation()
  const [error, setError] = useState(false)
  const loginWithGoogle = useLoginWithGoogle()

  useEffect(() => {
    auth()
  }, [])

  async function auth() {
    const queryParams = new URLSearchParams(location.search)
    const code = queryParams.get('code')
    try {
      const resp = await axios.post<{ idToken: string }>(
        `${env.CLOUDFLARE_WORKER_AUTH_PROVIDER_URL}/v2/auth/google`,
        { code, target: 'srp', environment: env.ENV },
      )
      await loginWithGoogle.mutateAsync(resp.data.idToken ?? '')
    } catch (error) {
      setError(true)
    }
  }

  if (error || loginWithGoogle.isError) {
    return <Navigate to="/app/login?error=true" />
  }

  return loginWithGoogle.isSuccess ? <Navigate to="/app/home" /> : null
}
