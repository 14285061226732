export const primary = {
  light: '#be99f9',
  main: '#624393',
  dark: '#4b2b7f',
}

export const secondary = {
  light: '#f0f0f0',
  main: '#FFFFFF',
  dark: '#e6e6e6',
}

export const text = {
  primary: '#FFFFFF',
  secondary: '#ffffff8c',
}
