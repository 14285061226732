import env from '@/config/env'
import FacebookLogin, { type SuccessResponse } from '@greatsumini/react-facebook-login'
import { Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { login } from '../../state/useAuth'

export default function FacebookButton() {
  const { t } = useTranslation()
  const responseFacebook = (response: SuccessResponse) => {
    login(response.accessToken)
  }
  return (
    <FacebookLogin
      appId={env.FACEBOOK_AUTH_API_CLIENT_ID}
      onSuccess={responseFacebook}
      render={renderProps => (
        <Button
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            p: 2,
            gap: 2,
            border: 1,
            borderRadius: 2,
            boxShadow: 'none',
            borderColor: 'divider',
            justifyContent: 'space-between',
            '&:hover': {
              boxShadow: 'none',
            },
          }}
          onClick={renderProps.onClick}
        >
          <Typography color="text.primary" fontWeight={600} fontSize={13} textTransform={'none'}>
            {t('login.continueWith', { agent: 'Facebook' })}
          </Typography>
          <img
            style={{ width: 24, height: 24, alignSelf: 'center' }}
            src="https://storage.googleapis.com/educabot-aec-cdn-1/educabot-cloud/images/facebook_logo.svg"
            alt="Facebook logo"
          />
        </Button>
      )}
    />
  )
}
