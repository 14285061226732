import UsersTable from '@/modules/home/users/components/usersTable'
import AddIcon from '@mui/icons-material/Add'
import { Box, Button, type Theme, Typography, useMediaQuery, useTheme } from '@mui/material'

export default function UsersPage() {
  const theme = useTheme()
  const isMobile = useMediaQuery<Theme>(t => t.breakpoints.down('md'))

  return (
    <Box sx={{ width: '100%', height: '100%', overflow: 'auto' }}>
      <Box
        sx={{
          width: '100%',
          display: 'grid',
          gridTemplateRows: '75px 75px 1fr',
          p: {
            xs: 2,
            sm: 2,
            md: 6,
            lg: 6,
            xl: 6,
          },
          pt: {
            xs: 4,
            sm: 4,
            md: 2,
            lg: 2,
            xl: 2,
          },
        }}
      >
        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' }}>
          <Box sx={{ alignSelf: 'center' }}>
            <Typography variant="h5" fontWeight={500}>
              Usuarios
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignSelf: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              size="large"
              variant="contained"
              color={theme.palette.mode === 'dark' ? 'secondary' : 'primary'}
              startIcon={<AddIcon />}
            >
              {isMobile ? 'Nuevo' : 'Nuevo usuario'}
            </Button>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} />
        <Box
          sx={{
            pt: {
              xs: 2,
              sm: 2,
              md: 2,
              lg: 2,
              xl: 2,
            },
            pb: {
              xs: 2,
              sm: 2,
              md: 2,
              lg: 2,
              xl: 2,
            },
            height: '100%',
            overflow: 'auto',
          }}
        >
          <UsersTable />
        </Box>
      </Box>
    </Box>
  )
}
