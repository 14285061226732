import env from '@/config/env'
import { queryClient } from '@/state/query'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useEffect } from 'react'

export type GetEmailProviderResponse = {
  email: string
  provider: string
}

export default function useGetEmailProvider(email: string) {
  const queryKey = 'get_email_provider'
  const query = useQuery({
    retry: false,
    queryKey: [queryKey],
    queryFn: () => {
      return axios.get<GetEmailProviderResponse>(
        env.CLOUDFLARE_WORKER_EMAIL_PROVIDER_DETECTOR_URL,
        {
          params: { email },
        },
      )
    },
    select: ({ data }) => data.provider,
  })

  useEffect(() => {
    queryClient.removeQueries({ queryKey: [queryKey] })
    query.refetch()
  }, [])

  return query
}
