import { useApp } from '@/modules/app/state/useApp'
import DomainIcon from '@mui/icons-material/Domain'
import GroupIcon from '@mui/icons-material/Group'
import ArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import ArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import {
  Box,
  type BoxProps,
  ButtonBase,
  Drawer,
  IconButton,
  type Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export interface SidePanelItem {
  icon: JSX.Element
  label: string
  link: string
  disabled?: boolean
}

export interface SidePanelItemWithID extends SidePanelItem {
  id: string
}

export interface SidePanelProps {
  items?: SidePanelItem[]
}

export default function SidePanelLayout({ items: initItems }: SidePanelProps) {
  const defaultItems: SidePanelItem[] = initItems ?? [
    {
      icon: <DomainIcon />,
      label: 'instituciones',
      link: '/home/institutions',
    },
    {
      icon: <GroupIcon />,
      label: 'usuarios',
      link: '/home/users',
    },
    {
      icon: <ManageAccountsIcon />,
      label: 'configuración',
      link: '/home/settings',
    },
  ]

  const navigate = useNavigate()
  const location = useLocation()
  const { sidePanelOpen, sidePanelCollapsed, toggleSidePanel, closeSidePanel } = useApp()
  const [items] = useState<SidePanelItemWithID[]>(
    defaultItems.map(item => ({ ...item, id: crypto.randomUUID() })),
  )
  const isMobile = useMediaQuery<Theme>(t => t.breakpoints.down('md'))
  const [selected, setSelected] = useState<string>(items[0].id)

  // detect the current selected item based on the url pathname
  useEffect(() => {
    const current = items.find(item => item.link === location.pathname)
    if (current) {
      setSelected(current.id)
      return
    }

    setSelected('')
  }, [location.pathname, items])

  const handleOnClickToggleCollapse = () => {
    toggleSidePanel()
  }

  const handleOnClickItem = (id: string, to: string) => () => {
    navigate(to)
    setSelected(id)
    closeSidePanel()
  }

  const handleOnClickClose = () => {
    closeSidePanel()
  }

  const content = (
    <Box
      sx={{
        width: sidePanelCollapsed ? '75px' : '250px',
        height: '100%',
        display: 'grid',
        gridTemplateRows: '100px 1fr',
        bgcolor: t => (t.palette.mode === 'dark' ? '#25202d' : '#2A205E08'),
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: isMobile ? 'none' : 'flex',
          justifyContent: sidePanelCollapsed ? 'center' : 'flex-end',
          pr: sidePanelCollapsed ? 0 : 2,
        }}
      >
        <Tooltip title={sidePanelCollapsed ? 'Expandir' : 'Contraer'} placement="bottom">
          <IconButton sx={{ alignSelf: 'center' }} onClick={handleOnClickToggleCollapse}>
            {sidePanelCollapsed ? (
              <ArrowRightIcon sx={{ color: t => t.palette.action.active }} />
            ) : (
              <ArrowLeftIcon sx={{ color: t => t.palette.action.active }} />
            )}
          </IconButton>
        </Tooltip>
      </Box>
      <Box
        sx={{
          p: 1,
          gridGap: 12,
          display: 'grid',
          gridAutoRows: 'min-content',
        }}
      >
        {items.map(item => {
          return (
            <ButtonBase
              key={item.id}
              aria-label={item.label}
              disabled={item.disabled}
              sx={{
                display: 'flex',
                borderRadius: '8px',
                width: sidePanelCollapsed ? 'auto' : '100%',
              }}
            >
              <Box
                sx={{
                  p: 2,
                  width: '100%',
                  display: 'flex',
                  borderRadius: 2,
                  cursor: 'pointer',
                  '&:hover': {
                    bgcolor: t => t.palette.action.hover,
                  },
                }}
                onClick={handleOnClickItem(item.id, item.link)}
              >
                {React.cloneElement<BoxProps>(item.icon, {
                  sx: {
                    alignSelf: 'center',
                    color: t => {
                      if (item.id === selected) {
                        if (t.palette.mode === 'light') return t.palette.primary.main
                        return t.palette.action.active
                      }
                      return t.palette.action.disabled
                    },
                  },
                })}
                <Typography
                  sx={{
                    fontWeight: 500,
                    pl: sidePanelCollapsed ? 0 : 2,
                    textTransform: 'capitalize',
                    color: item.id === selected ? 'text.main' : 'text.secondary',
                  }}
                >
                  {!sidePanelCollapsed ? item.label : ''}
                </Typography>
              </Box>
            </ButtonBase>
          )
        })}
      </Box>
    </Box>
  )

  if (isMobile) {
    return (
      <Drawer open={sidePanelOpen} onClose={handleOnClickClose}>
        {content}
      </Drawer>
    )
  }

  return content
}
