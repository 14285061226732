import env from '@/config/env'
import { useAuth } from '@/modules/auth/state/useAuth'
import { Box } from '@mui/system'
import { useGoogleLogin } from '@react-oauth/google'
import axios from 'axios'
import useSignupWithGoogle from '../queries/useSignupWithGoogle'
import AuthButton from './authButton'

interface GoogleButtonProps {
  onError: (err: string) => void
}

const GoogleButton = ({ onError }: GoogleButtonProps) => {
  const { login } = useAuth()
  const mutation = useSignupWithGoogle()

  const handleMutation = async (idToken: string) => {
    try {
      const { data } = await mutation.mutateAsync(idToken)
      const { token } = data.description
      login(token)
    } catch (error) {
      mutation.error && onError(mutation.error.message)
      mutation.reset()
    }
  }

  const handleOnSignUpSuccess = async (code: string) => {
    try {
      const { data } = await axios.post<{ idToken: string }>(
        `${env.CLOUDFLARE_WORKER_AUTH_PROVIDER_URL}/v1/auth/google`,
        { code, target: 'srp' },
      )
      const { idToken } = data
      await handleMutation(idToken)
    } catch (error) {
      onError('No pudimos validar tu identidad,\npor favor intenta nuevamente.')
    }
  }

  const signUp = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: ({ code }) => handleOnSignUpSuccess(code),
  })

  const handleOnClick = () => {
    signUp()
  }

  return (
    <Box onClick={handleOnClick}>
      <AuthButton type="google" />
    </Box>
  )
}

export default GoogleButton
