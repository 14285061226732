import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { InputAdornment, TextField } from '@mui/material'

export interface SearchInstitutionProps {
  onChange: (value: string) => void
}

export default function SearchInstitution({ onChange }: SearchInstitutionProps) {
  return (
    <TextField
      sx={{
        width: {
          xs: '60%',
          sm: '60%',
          md: 320,
          lg: 320,
          xl: 320,
        },
        borderRadius: 2,
      }}
      size="medium"
      onChange={e => onChange(e.target.value)}
      aria-label="Buscar por nombre o código de institución"
      placeholder="Buscar institución"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchOutlinedIcon sx={{ color: 'secondary.dark' }} />
          </InputAdornment>
        ),
      }}
    />
  )
}
