import env from '@/config/env'
import { useAuth } from '@/modules/auth/state/useAuth'
import FacebookLogin, { type SuccessResponse } from '@greatsumini/react-facebook-login'
import { Box } from '@mui/system'
import useSignupWithFacebook from '../queries/useSignupWithFacebook'
import AuthButton from './authButton'

interface FacebooButtonProps {
  onError: (err: string) => void
}

const FacebookButton = ({ onError }: FacebooButtonProps) => {
  const signupWithFacebook = useSignupWithFacebook()
  const { login } = useAuth()

  const handleSignup = async (res: SuccessResponse) => {
    const { data } = await signupWithFacebook.mutateAsync(res.accessToken)
    const { token } = data.description
    login(token)
  }

  return (
    <FacebookLogin
      appId={env.FACEBOOK_AUTH_API_CLIENT_ID}
      onSuccess={handleSignup}
      onFail={_err => onError('No pudimos validar tu identidad,\npor favor intenta nuevamente.')}
      render={renderProps => (
        <Box onClick={renderProps.onClick}>
          <AuthButton type="facebook" />
        </Box>
      )}
    />
  )
}

export default FacebookButton
