import { Box, type BoxProps } from '@mui/material'
import type React from 'react'

export interface ModalBodyProps extends BoxProps {
  children?: React.ReactNode
}

export const ModalBody: React.FC<ModalBodyProps> = ({ children, sx, ...props }) => {
  return (
    <Box
      sx={{
        flex: 1,
        p: 4,
        ...sx,
      }}
      {...props}
    >
      {children}
    </Box>
  )
}
