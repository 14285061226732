import { Box } from '@mui/material'

interface WrapperLayoutProps {
  children: React.ReactNode
}

export default function WrapperLayout({ children }: WrapperLayoutProps) {
  const headerHeight = 70
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'grid',
        bgcolor: 'background.default',
        gridTemplateRows: `${headerHeight}px 1fr`,
      }}
    >
      {children}
    </Box>
  )
}
