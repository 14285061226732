import { Box, type BoxProps } from '@mui/material'
import type React from 'react'

export interface ModalFooterProps extends BoxProps {
  children?: React.ReactNode
}

export const ModalFooter: React.FC<ModalFooterProps> = ({ sx, ...props }) => {
  return (
    <Box
      component={'footer'}
      sx={{
        width: '100%',
        flex: 0.1,
        px: 1,
        display: 'flex',
        justifyContent: 'flex-end',
        ...sx,
      }}
      {...props}
    />
  )
}
