import { client } from '@/services/client'
import type { ClientError } from '@educabot/cloud-client'
import type { SignupWithFacebookV1 } from '@educabot/cloud-client/lib/lms/signup/signupWithFacebook'
import { useMutation } from '@tanstack/react-query'
import type { AxiosResponse } from 'axios'

export default function useSignupWithMicrosoft() {
  const query = useMutation<
    AxiosResponse<SignupWithFacebookV1.SignUpWithFacebookResponse>,
    ClientError,
    string
  >({
    mutationFn: (token: string) => client.lms.signup.SignupWithMicrosoftV1({ token }),
  })
  return query
}
