import { Box } from '@mui/system'

export interface ModalContentProps {
  children?: React.ReactNode
  size?: 'small' | 'medium' | 'large'
}

export function ModalContent({ children, size = 'medium', ...props }: ModalContentProps) {
  const sizeOptions = {
    small: {
      width: {
        xs: '90%',
        sm: '90%',
        md: 500,
        lg: 600,
      },
      height: {
        xs: '90%',
        sm: '90%',
        md: 400,
        lg: 400,
      },
    },
    medium: {
      width: {
        xs: '90%',
        sm: '90%',
        md: 700,
        lg: 800,
      },
      height: {
        xs: '90%',
        sm: '90%',
        md: 600,
        lg: 600,
      },
    },
    large: {
      width: {
        xs: '90%',
        sm: '90%',
        md: 800,
        lg: 880,
      },
      height: {
        xs: '90%',
        sm: '90%',
        md: 600,
        lg: 640,
      },
    },
  }

  const selectedSize = sizeOptions[size]

  return (
    <Box
      component="section"
      sx={{
        display: 'flex',
        position: 'absolute' as const,
        top: '50%',
        left: '50%',
        width: selectedSize.width,
        height: selectedSize.height,
        bgcolor: 'background.paper',
        transform: 'translate(-50%, -50%)',
        boxShadow: 24,
        p: {
          xs: 2,
          sm: 2,
          md: 2.8,
          lg: 2.8,
        },
        borderRadius: 2,
        bgColor: 'default.background',
        flexDirection: 'column',
        ...props,
      }}
    >
      {children}
    </Box>
  )
}
