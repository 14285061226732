import { web } from '@/services/client'
import type { ClientError } from '@educabot/cloud-client'
import type { Web } from '@educabot/cloud-client/lib/web'
import { useQuery } from '@tanstack/react-query'

export default function usePaginateGetUsers(
  role: string,
  page: number,
  rowsPerPage: number,
  organizationId: number,
) {
  const query = useQuery<Web.Users.GetV2.GetUsersResponse['description'], ClientError>({
    queryKey: ['get_users_paginate', page],
    queryFn: async () => {
      const data = await web.users.get({ role, page, size: rowsPerPage, organizationId })
      return data.data.description
    },
    initialData: {
      list: [],
      more: false,
      failed: [],
    },
  })

  return query
}
