import { Box, FormControl, FormLabel, TextField, Typography } from '@mui/material'
import type { FieldErrors, UseFormRegister } from 'react-hook-form'
import type { InstitutionModalInputs } from '../../types/institution'

interface StepOneProps {
  errors: FieldErrors<InstitutionModalInputs>
  register: UseFormRegister<InstitutionModalInputs>
}

export default function StepOne({ errors, register }: StepOneProps) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box mb={4}>
        <Typography mb={1} variant="h5">
          Para empezar, vamos a configurar una institución.
        </Typography>
        <Typography fontSize={t => t.typography.pxToRem(16)} color="text.secondary">
          Ingresá el identificador único de institución que corresponda a tu país.
        </Typography>
      </Box>
      <FormControl required error={!!errors.idj?.message}>
        <FormLabel sx={{ color: 'text.primary', fontWeight: 500, mb: 1 }}>
          Identificador único de institución
        </FormLabel>
        <TextField
          placeholder="Número del identificador"
          error={!!errors.idj?.message}
          helperText={errors.idj?.message}
          {...register('idj', {
            required: 'Este campo es requerido',
          })}
        />
      </FormControl>
    </Box>
  )
}
