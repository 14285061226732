import env from '@/config/env'
import { useApp } from '@/modules/app/state/useApp'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined'
import DomainOutlinedIcon from '@mui/icons-material/DomainOutlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined'
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined'
import LocalActivityOutlinedIcon from '@mui/icons-material/LocalActivityOutlined'
import PrecisionManufacturingOutlinedIcon from '@mui/icons-material/PrecisionManufacturingOutlined'
import PriceCheckOutlinedIcon from '@mui/icons-material/PriceCheckOutlined'
import RouteOutlinedIcon from '@mui/icons-material/RouteOutlined'
import SettingsApplicationsOutlinedIcon from '@mui/icons-material/SettingsApplicationsOutlined'
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined'
import { Box, Typography } from '@mui/material'
import { Outlet } from 'react-router-dom'

export default function SignUpPage() {
  const { isDarkMode } = useApp()
  return (
    <Box
      sx={t => ({
        width: '100%',
        height: '100%',
        display: 'grid',
        overflow: 'auto',
        [t.breakpoints.up('lg')]: {
          gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
        },
        [t.breakpoints.down('lg')]: {
          gridTemplateRows: '100vh 100vh',
          gridTemplateAreas: "'b' 'a'",
        },
      })}
    >
      <Box
        sx={t => ({
          width: '100%',
          height: '100%',
          display: 'grid',
          background: 'linear-gradient(45deg, #2A205E00, #2A205E0A)',
          gridTemplateRows: '6rem 1fr',
          [t.breakpoints.down('lg')]: {
            gridArea: 'a',
            gridTemplateRows: '1fr',
          },
        })}
      >
        <Box
          sx={t => ({
            pl: t.spacing(8),
            display: {
              xs: 'none',
              sm: 'none',
              md: 'none',
              lg: 'flex',
            },
          })}
        >
          <img
            style={{ width: '25%', alignSelf: 'center' }}
            src={
              isDarkMode
                ? `${env.ASSETS_CDN_URL}/images/educabot_cloud_logo_white.svg`
                : `${env.ASSETS_CDN_URL}/images/educabot_cloud_logo.svg`
            }
            alt="Educabot Cloud"
          />
        </Box>
        <Box
          sx={t => ({
            display: 'flex',
            p: {
              xs: t.spacing(4),
              sm: t.spacing(4),
              md: t.spacing(8),
              lg: t.spacing(8),
            },
            pt: {
              xs: t.spacing(4),
              sm: t.spacing(4),
              md: t.spacing(4),
              lg: t.spacing(4),
            },
            flexDirection: 'column',
          })}
        >
          <Box sx={{ display: 'flex' }}>
            <Box
              sx={{
                width: {
                  xs: '100%',
                  sm: '100%',
                  md: '100%',
                  lg: '50%',
                },
              }}
            >
              <img
                style={{ width: '100%' }}
                alt="Da de alta tu escuela en una sola plataforma"
                src={
                  isDarkMode
                    ? `${env.ASSETS_CDN_URL}/images/da_de_alta_tu_escuela_en_una_sola_plataforma_white.svg`
                    : `${env.ASSETS_CDN_URL}/images/da_de_alta_tu_escuela_en_una_sola_plataforma.svg`
                }
              />
            </Box>
          </Box>
          <Box
            sx={t => ({
              height: '100%',
              display: 'flex',
              gap: t.spacing(8),
              pt: t.spacing(8),
              [t.breakpoints.down('lg')]: {
                flexDirection: 'column',
              },
            })}
          >
            <Box sx={t => ({ display: 'flex', flexDirection: 'column', gap: t.spacing(2.5) })}>
              <Typography
                sx={{
                  fontWeight: 600,
                  textTransform: 'uppercase',
                  color: 'primary.main',
                }}
              >
                Gestión
              </Typography>
              <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                <DomainOutlinedIcon sx={{ marginRight: 1, color: 'primary.main' }} />
                Gestión de infraestructura
              </Typography>
              <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                <SettingsApplicationsOutlinedIcon sx={{ marginRight: 1, color: 'primary.main' }} />
                Administración escolar
              </Typography>
              <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                <PriceCheckOutlinedIcon sx={{ marginRight: 1, color: 'primary.main' }} />
                Facturación y cobranza
              </Typography>
              <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                <StorefrontOutlinedIcon sx={{ marginRight: 1, color: 'primary.main' }} />
                Marketplace de equipamiento
              </Typography>
              <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                <LocalActivityOutlinedIcon sx={{ marginRight: 1, color: 'primary.main' }} />
                Matriculación
              </Typography>
              <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                <AutoAwesomeOutlinedIcon sx={{ marginRight: 1, color: 'primary.main' }} />
                Inteligencia artificial
              </Typography>
            </Box>
            <Box sx={t => ({ display: 'flex', flexDirection: 'column', gap: t.spacing(2.5) })}>
              <Typography
                sx={{
                  fontWeight: 600,
                  textTransform: 'uppercase',
                  color: 'primary.main',
                }}
              >
                Enseñanza
              </Typography>
              <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                <PrecisionManufacturingOutlinedIcon
                  sx={{ marginRight: 1, color: 'primary.main' }}
                />
                Robótica y Programación
              </Typography>
              <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                <EventAvailableOutlinedIcon sx={{ marginRight: 1, color: 'primary.main' }} />
                Planificación de currícula
              </Typography>
              <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                <EmailOutlinedIcon sx={{ marginRight: 1, color: 'primary.main' }} />
                Comunicaciones
              </Typography>
              <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                <ArticleOutlinedIcon sx={{ marginRight: 1, color: 'primary.main' }} />
                Boletines
              </Typography>
              <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                <RouteOutlinedIcon sx={{ marginRight: 1, color: 'primary.main' }} />
                Seguimiento del trayecto estudiantil
              </Typography>
              <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                <HowToRegOutlinedIcon sx={{ marginRight: 1, color: 'primary.main' }} />
                Asistencias
              </Typography>
            </Box>
          </Box>
          <Box sx={{ width: '100%', height: '100%', pt: 6 }}>
            <Typography
              sx={{
                mb: 4,
                textTransform: 'uppercase',
                color: 'text.primary',
              }}
            >
              Integración con herramientas de tecnología educativa
            </Typography>
            <Box
              sx={t => ({
                width: '80%',
                display: 'flex',
                gap: t.spacing(6),
                justifyContent: 'space-between',
                flexWrap: {
                  xs: 'wrap',
                  sm: 'wrap',
                  md: 'wrap',
                  lg: 'nowrap',
                },
              })}
            >
              <img
                style={{ width: '40%' }}
                src={`${env.ASSETS_CDN_URL}/images/sistema_de_gestion_digital_estatales.svg`}
                alt="Sistema de gestion digital estatales"
              />
              <img
                style={{ width: '40%' }}
                src={`${env.ASSETS_CDN_URL}/images/matific.svg`}
                alt="Matific"
              />
              <img
                style={{ width: '40%' }}
                src={`${env.ASSETS_CDN_URL}/images/google_classrom.svg`}
                alt="Google Classrom"
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={t => ({
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          [t.breakpoints.down('lg')]: {
            gridArea: 'b',
          },
        })}
      >
        <Box sx={{ width: '100%', minHeight: '4rem' }}>
          <Box
            sx={t => ({
              width: {
                xs: '60%',
                sm: '60%',
                md: '30%',
                lg: '30%',
              },
              height: '100%',
              pl: {
                xs: t.spacing(4),
                sm: t.spacing(4),
                md: t.spacing(4),
                lg: t.spacing(6),
              },
              display: {
                xs: 'flex',
                sm: 'flex',
                md: 'flex',
                lg: 'none',
              },
            })}
          >
            <img
              style={{ width: '80%', alignSelf: 'center' }}
              src={`${env.ASSETS_CDN_URL}/images/educabot_cloud_logo.svg`}
              alt="Educabot Cloud"
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignSelf: 'center',
            maxWidth: {
              xs: '100%',
              sm: 550,
              md: 550,
              lg: 550,
            },
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  )
}
