import Logo from '@/components/logo'
import GlobalSearch from '@/modules/app/header/globalSearch'
import { useApp } from '@/modules/app/state/useApp'
import AccountMenu from '@/modules/user/components/accountMenu'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline'
import { Badge, Box, IconButton, alpha, useMediaQuery } from '@mui/material'
import { Avatar } from '@mui/material'
import type { Theme } from '@mui/material'
import { common } from '@mui/material/colors'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export default function HeaderLayout() {
  const { openSidePanel } = useApp()
  const { user } = useApp()
  const navigate = useNavigate()
  const [accountAnchorEl, setAccountAnchorEl] = useState<null | HTMLElement>(null)
  const isMobile = useMediaQuery<Theme>(t => t.breakpoints.down('md'))

  const handleOnCloseAccount = () => {
    setAccountAnchorEl(null)
  }

  const handleOnClickAvatar = (e: React.MouseEvent<HTMLElement>) => {
    setAccountAnchorEl(e.currentTarget)
  }

  const handleOpenAndExpandSidePanel = () => {
    openSidePanel()
  }

  const handleOnClickLogo = () => {
    navigate('/home/institutions')
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'grid',
        bgcolor: 'primary.main',
        gridTemplateColumns: 'auto 1fr auto',
        pl: {
          xs: 2.8,
          sm: 2.8,
          md: 8,
          lg: 8,
          xl: 8,
        },
        pr: {
          xs: 2.8,
          sm: 2.8,
          md: 8,
          lg: 8,
          xl: 8,
        },
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          gap: {
            xs: 2.8,
            sm: 2.8,
            md: 2.8,
            lg: 0,
            xl: 0,
          },
        }}
      >
        {isMobile && (
          <IconButton onClick={handleOpenAndExpandSidePanel}>
            <ViewHeadlineIcon sx={{ alignSelf: 'center', color: alpha(common.white, 0.5) }} />
          </IconButton>
        )}
        <Logo sx={{ cursor: 'pointer', userSelect: 'none' }} onClick={handleOnClickLogo} />
      </Box>
      <Box
        sx={{
          width: '100%',
          display: {
            xs: 'none',
            sm: 'none',
            md: 'flex',
            lg: 'flex',
            xl: 'flex',
          },
          pl: 8,
          pr: 8,
          justifyContent: 'flex-end',
        }}
      >
        {!isMobile && <GlobalSearch sx={{ alignSelf: 'center' }} onChange={() => {}} />}
      </Box>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Box
          sx={{
            gap: 2.8,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {isMobile && <SearchOutlinedIcon sx={{ color: 'common.white' }} />}
          {!isMobile && <HelpOutlineIcon sx={{ color: 'common.white' }} />}
          <Badge max={99} color="warning" overlap="circular" badgeContent={6}>
            <NotificationsNoneIcon sx={{ color: 'common.white' }} />
          </Badge>
          <AccountMenu
            name={user.name}
            lastName={user.lastName}
            profile={user.profile}
            avatar={user.avatar}
            anchorEl={accountAnchorEl}
            onClose={handleOnCloseAccount}
          />
          <IconButton size="small" onClick={handleOnClickAvatar}>
            <Avatar src={user.avatar} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  )
}
