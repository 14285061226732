import { client } from '@/services/client'
import { queryClient } from '@/state/query'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export interface UseAuth {
  token: string
  isAuthenticated: boolean
  login: (token: string) => void
  logout: () => void
}

export const useAuth = create<UseAuth>()(
  persist(
    set => ({
      token: '',
      isAuthenticated: false,
      login: (token: string) => {
        client.client.updateToken(token)
        set({ token, isAuthenticated: true })
      },
      logout: () => {
        queryClient.clear()
        client.client.updateToken('')
        set({ token: '', isAuthenticated: false })
      },
    }),
    {
      name: 'auth',
      partialize: state => ({
        token: state.token,
        isAuthenticated: state.isAuthenticated,
      }),
      onRehydrateStorage: () => state => {
        if (!state?.token) return
        client.client.updateToken(state.token)
        state.isAuthenticated = true
      },
    },
  ),
)

export const { token, isAuthenticated, login, logout } = useAuth.getState()
