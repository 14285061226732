import env from '@/config/env'
import { Box, type BoxProps, styled } from '@mui/system'

interface LogoProps extends BoxProps {
  image?: string
}

const Image = styled('img')({
  width: '100%',
  height: 'auto',
  alignSelf: 'center',
})

export default function Logo({ sx, ...props }: LogoProps) {
  return (
    <Box
      sx={{ ...sx, width: '100%', height: 'auto', display: 'flex', justifyContent: 'center' }}
      {...props}
    >
      <Image
        src={`${env.ASSETS_CDN_URL}/images/educabot_cloud_logo_white_small.svg`}
        alt="Educabot cloud logo"
      />
    </Box>
  )
}
