import env from '@/config/env'
import useGetEmailProvider from '@/modules/signup/queries/useGetEmailProvider'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Typography } from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'

export default function SignUpRegisterSucessPage() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const email = searchParams.get('email')
  const name = searchParams.get('name')
  const lastName = searchParams.get('lastName')
  const countryId = searchParams.get('countryId')

  const { data: provider, isLoading, isSuccess } = useGetEmailProvider(email ?? '')
  const defaultProvider = provider === 'unknown' ? 'google' : provider ?? 'google'

  const handleClickModifyEmail = () => {
    navigate(
      `/sign_up/register?email=${email}&name=${name}&lastName=${lastName}&countryId=${countryId}`,
    )
  }

  const providers = {
    google: {
      image: `${env.ASSETS_CDN_URL}/images/gmail_logo.svg`,
      text: 'Abrir Gmail',
      action: () => {
        window.open('https://mail.google.com/mail/u/0/#inbox', '_blank')
      },
    },
    outlook: {
      image: `${env.ASSETS_CDN_URL}/images/outlook_logo.svg`,
      text: 'Abrir Outlook',
      action: () => {
        window.open('https://outlook.live.com/owa/', '_blank')
      },
    },
    icloud: {
      image: `${env.ASSETS_CDN_URL}/images/icloud_logo.svg`,
      text: 'Abrir iCloud',
      action: () => {
        window.open('https://www.icloud.com/mail', '_blank')
      },
    },
  }[defaultProvider]

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'grid',
      }}
    >
      <Box sx={{ width: '100%', height: '100%', display: 'flex' }} />
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          padding: '2rem',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ width: '100%', display: 'flex', marginBottom: 6 }}>
          <Typography fontWeight={600} variant="h5">
            Revisá tu correo
          </Typography>
        </Box>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: '60%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ marginBottom: 4 }}>
              <Typography component="span" variant="h6" color="primary.main">
                Enviamos un correo a{' '}
                <Typography variant="h6" fontWeight={600}>
                  {searchParams.get('email')}
                </Typography>
                con un link para finalizar el registro.
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {isSuccess && provider !== 'unknown' ? (
                <LoadingButton
                  size="large"
                  variant="contained"
                  loading={isLoading}
                  onClick={providers?.action}
                >
                  <img
                    style={{ width: 28, paddingRight: 10, display: isLoading ? 'none' : 'block' }}
                    alt="Mail logo"
                    src={providers?.image}
                  />
                  {providers?.text}
                </LoadingButton>
              ) : null}
              <Button variant="outlined" size="large" onClick={handleClickModifyEmail}>
                Modificar correo
              </Button>
              <Typography sx={{ color: 'text.primary', alignSelf: 'center' }}>
                ¿No encontrás el correo? Revisá tu bandeja de spam.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
