import { lms } from '@/services/client'
import type { ClientError } from '@educabot/cloud-client'
import type { LoginWithGoogleResponse } from '@educabot/cloud-client/lib/lms/login/loginWithGoogle/loginWithGoogle'
import { useMutation } from '@tanstack/react-query'
import type { AxiosResponse } from 'axios'
import { useAuth } from '../../state/useAuth'

export default function useLoginWithGoogle() {
  const { login } = useAuth()

  const query = useMutation<AxiosResponse<LoginWithGoogleResponse>, ClientError, string>({
    mutationFn: async token => {
      return lms.login.loginWithGoogle({ token })
    },
    onSuccess: data => {
      login(data.data.description.token)
    },
  })

  return query
}
