import { Box, Modal as ModalBase, type ModalProps as ModalBaseProps } from '@mui/material'

export interface ModalProps extends Omit<ModalBaseProps, 'children'> {
  children?: React.ReactNode | React.ReactNode[]
}

export function Modal({ children, ...props }: ModalBaseProps) {
  return (
    <ModalBase {...props}>
      <Box>{children}</Box>
    </ModalBase>
  )
}
