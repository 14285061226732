import env from '@/config/env'
import FacebookButton from '@/modules/signup/components/facebookButton'
import GoogleButton from '@/modules/signup/components/googleButton'
import MicrosoftButton from '@/modules/signup/components/microsoftButton'
import useCreateProspect from '@/modules/signup/queries/useCreateProspect'
import useGetCountryCode from '@/modules/signup/queries/useGetCountryCode'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Box,
  Divider,
  FormControl,
  Link,
  MenuItem,
  Select,
  type SelectChangeEvent,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'

const countries = [
  {
    id: 0,
    label: 'Argentina',
    flag: `${env.ASSETS_CDN_URL}/flags/arg.svg`,
  },
  {
    id: 1,
    label: 'Resto del mundo',
    flag: `${env.ASSETS_CDN_URL}/miscellaneous/globe.svg`,
  },
]

type RegisterValues = {
  name: string
  lastName: string
  email: string
  countryId: number
}

type Country = {
  id: number
  label: string
  flag: string
}

export default function SignUpRegisterPage() {
  const navigate = useNavigate()
  const createProspect = useCreateProspect()
  const countryCode = useGetCountryCode()
  const [error, setError] = useState('')
  const [searchParams] = useSearchParams()
  const [selectedCountry, setSelectedCountry] = useState<Country>(countries[0])

  const form = useForm<RegisterValues>({
    defaultValues: {
      name: searchParams.get('name') ?? '',
      email: searchParams.get('email') ?? '',
      lastName: searchParams.get('lastName') ?? '',
      countryId: Number.parseInt(searchParams.get('countryId') ?? '0', 10),
    },
  })

  useEffect(() => {
    if (countryCode.isSuccess) {
      const country = countryCode.data?.country === 'AR' ? countries[0] : countries[1]
      setSelectedCountry(country)
    }

    if (countryCode.isError) {
      setSelectedCountry(countries[1])
    }
  }, [countryCode.status])

  useEffect(() => {
    if (createProspect.isSuccess) {
      const values = form.getValues()
      const email = searchParams.get('email')
      navigate(
        `/sign_up/register_sucess?email=${values.email ?? email}&name=${values.name}&lastName=${values.lastName}&countryId=${values.countryId}`,
      )
    }

    if (createProspect.isError) {
      if (createProspect.error.statusCode === 403) {
        form.setError('email', {
          type: 'manual',
          message: createProspect.error.message,
        })

        return
      }

      // other errors
      setError(createProspect.error.message)
      createProspect.reset()
    }
  }, [form, createProspect])

  const handleOnChangeContry = (e: SelectChangeEvent<number>) => {
    const c = countries.find(d => e.target.value === d.id)
    if (c) setSelectedCountry(c)
  }

  const handleOnSubmitId: SubmitHandler<RegisterValues> = data => {
    createProspect.mutate({
      email: data.email,
      name: data.name,
      lastName: data.lastName,
      countryId: selectedCountry.id,
      recaptchaToken: '',
    })
  }

  const handleOnSubmit = () => {
    form.handleSubmit(handleOnSubmitId)()
  }

  const handleOnAuthError = (err: string) => {
    setError(err)
  }

  const handleClose = (_e: Event | React.SyntheticEvent) => {
    setError('')
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
      component={'form'}
    >
      <Snackbar
        open={!!error}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert variant="filled" severity="error">
          {error}
        </Alert>
      </Snackbar>
      <Box
        sx={t => ({
          display: 'flex',
          pt: {
            xs: t.spacing(4),
            sm: t.spacing(4),
            md: t.spacing(0),
            lg: t.spacing(0),
          },
          pl: {
            xs: 0,
            sm: 0,
            md: t.spacing(4),
            lg: t.spacing(4),
          },
          justifyContent: {
            xs: 'center',
            sm: 'center',
            md: 'flex-start',
            lg: 'flex-start',
          },
        })}
      >
        <Typography fontWeight={600} variant="h6">
          Registrate en Educabot Cloud
        </Typography>
      </Box>
      <Box
        sx={t => ({
          width: '100%',
          height: '100%',
          display: 'flex',
          gap: t.spacing(2),
          padding: t.spacing(4),
          flexDirection: 'column',
        })}
      >
        <Box
          sx={t => ({
            width: 'fit-content',
            display: 'flex',
            alignSelf: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: {
              xs: t.spacing(8),
              sm: t.spacing(8),
              md: t.spacing(10),
              lg: t.spacing(10),
            },
          })}
        >
          <GoogleButton onError={handleOnAuthError} />
          <MicrosoftButton onError={handleOnAuthError} />
          <FacebookButton onError={handleOnAuthError} />
        </Box>
        <Divider sx={{ mt: 2, mb: 2 }}>
          <Typography sx={{ fontSize: '1rem', color: 'text.primary' }}>
            O registrate con tu correo electrónico
          </Typography>
        </Divider>
        <Box
          sx={t => ({
            display: 'grid',
            gap: t.spacing(2),
            gridTemplateColumns: '1fr 1fr',
            gridTemplateRows: 'auto auto',
          })}
        >
          <FormControl sx={{ display: 'flex', flex: 1 }}>
            <TextField
              required
              variant="outlined"
              label={'Nombre'}
              autoComplete="true"
              placeholder={'Ingresa tu nombre'}
              error={!!form.formState.errors.name}
              helperText={form.formState.errors.name?.message}
              {...form.register('name', {
                required: {
                  value: true,
                  message: t('required', { ns: 'validation' }),
                },
                minLength: {
                  value: 4,
                  message: t('min', { ns: 'validation', min: 4 }),
                },
                maxLength: {
                  value: 40,
                  message: t('max', { ns: 'validation', max: 40 }),
                },
              })}
            />
          </FormControl>
          <FormControl sx={{ display: 'flex', flex: 1 }}>
            <TextField
              required
              variant="outlined"
              label={'Apellido'}
              autoComplete="true"
              placeholder={'Ingresa tu apellido'}
              error={!!form.formState.errors.lastName}
              helperText={form.formState.errors.lastName?.message}
              {...form.register('lastName', {
                required: {
                  value: true,
                  message: t('required', { ns: 'validation' }),
                },
                minLength: {
                  value: 4,
                  message: t('min', { ns: 'validation', min: 4 }),
                },
                maxLength: {
                  value: 40,
                  message: t('max', { ns: 'validation', max: 40 }),
                },
              })}
            />
          </FormControl>
          <FormControl sx={{ gridColumn: 'span 2' }}>
            <Typography sx={{ color: 'text.primary', fontWeight: 600, fontSize: '1rem', mb: 1 }}>
              Ubicación de tu institución educativa
            </Typography>
            <Select
              displayEmpty
              value={selectedCountry.id}
              renderValue={() => {
                return (
                  <Box
                    sx={t => ({
                      display: 'flex',
                      gap: t.spacing(2),
                      alignItems: 'center',
                    })}
                  >
                    <img
                      aria-label="country_flag"
                      style={{ width: 20, height: 20 }}
                      src={selectedCountry.flag}
                    />
                    <Typography fontWeight={500}>{selectedCountry.label}</Typography>
                  </Box>
                )
              }}
              {...form.register('countryId', {
                required: true,
                onChange: handleOnChangeContry,
              })}
            >
              {countries.map(f => {
                return (
                  <MenuItem key={f.id} value={f.id} sx={t => ({ gap: t.spacing(2) })}>
                    <img
                      aria-label="select_country"
                      style={{ width: 20, height: 20 }}
                      src={f.flag}
                    />
                    <Typography fontWeight={500}>{f.label}</Typography>
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <FormControl sx={{ gridColumn: 'span 2' }}>
            <TextField
              required
              variant="outlined"
              label={'Email'}
              autoComplete="true"
              placeholder={'Ingresa tu email'}
              error={!!form.formState.errors.email}
              helperText={form.formState.errors.email?.message}
              {...form.register('email', {
                required: {
                  value: true,
                  message: t('required', { ns: 'validation' }),
                },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: t('email', { ns: 'validation' }),
                },
              })}
            />
            <Typography
              sx={{ gridColumn: 'span 2', fontSize: 14, color: 'text.secondary', marginTop: 1 }}
            >
              Asegurate de indicar correctamente tu correo, vamos a usarlo para finalizar el proceso
              de registro.
            </Typography>
          </FormControl>
        </Box>
        <LoadingButton
          loading={createProspect.isPending}
          sx={t => ({ gridColumn: 'span 2', marginTop: t.spacing(2) })}
          size="large"
          variant="contained"
          onClick={handleOnSubmit}
        >
          Continuar
        </LoadingButton>
        <Typography sx={{ gridColumn: 'span 2' }}>
          Al registrarte, aceptás los{' '}
          <Link
            sx={{ fontWeight: 600, cursor: 'pointer' }}
            href="https://legals.educabot.workers.dev"
          >
            términos y condiciones
          </Link>{' '}
          y la{' '}
          <Link sx={{ fontWeight: 600, cursor: 'pointer' }} href="https://educabot.com/politicas">
            política de privacidad
          </Link>{' '}
          de Educabot Cloud.
        </Typography>
      </Box>
    </Box>
  )
}
