import FacebookButton from '@/modules/auth/login/components/facebookButton'
import GoogleButton from '@/modules/auth/login/components/googleButton'
import type { IdentificationInputs } from '@/modules/auth/login/components/identification'
import Identification from '@/modules/auth/login/components/identification'
import MicrosoftButton from '@/modules/auth/login/components/microsoftButton'
import useGetWhichLogin from '@/modules/auth/login/queries/useGetWhichLogin'
import useGetLoginIdTypes from '@/modules/auth/login/queries/useLoginIdTypes'
import { capFirst } from '@/utils/text'
import type { Type as IdentificationType } from '@educabot/cloud-client/lib/lms/login/getIdTypes/getIdTypes'
import type { VERIFICATION_TYPE } from '@educabot/cloud-client/lib/lms/login/getWhich/getWhich'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import LoadingButton from '@mui/lab/LoadingButton'
import type { SelectChangeEvent } from '@mui/material'
import { Box, Divider, IconButton, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import type { SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

type LoginPageParams = {
  id: string
  idType: string
}

export default function LoginIdentificationPage() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const whichLogin = useGetWhichLogin()
  const loginIdTypes = useGetLoginIdTypes()
  const params = useParams<LoginPageParams>()
  const defaultDocType = params.idType ? Number(params.idType) : 1
  const [idType, setIdType] = useState<IdentificationType>(
    loginIdTypes.data.find(d => d.id === defaultDocType) ?? loginIdTypes.data[0],
  )

  const form = useForm<IdentificationInputs>({
    defaultValues: {
      id: params.id ?? '',
      idType: params.idType ?? idType.id.toString(),
    },
  })

  useEffect(() => {
    if (whichLogin.isSuccess) {
      handleLoginMetod(whichLogin.data.data.description.method)
    }

    if (whichLogin.isError) {
      form.setError('id', {
        message: whichLogin.error.message,
      })
    }
  }, [whichLogin.status])

  function handleLoginMetod(type: VERIFICATION_TYPE) {
    const data = form.getValues()
    switch (type) {
      case 'password': {
        navigate(`/login/password/${data.id}/${data.idType}`)
        break
      }

      case 'questions': {
        navigate(`/login/questions/${data.id}/${data.idType}`)
      }
    }
  }

  const handleOnSubmitId: SubmitHandler<IdentificationInputs> = data => {
    whichLogin.mutate({
      id: data.id,
      idType: data.idType,
    })
  }

  const handleOnChangeType = (e: SelectChangeEvent<number>) => {
    const type = loginIdTypes.data.find(d => e.target.value === d.id)
    if (type) setIdType(type)
  }

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      form.handleSubmit(handleOnSubmitId)()
    }
  }

  return (
    <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box sx={{ width: 40, height: 40, display: 'flex' }}>
        <IconButton sx={{ pointerEvents: 'none' }}>
          <ArrowBackIcon sx={{ color: 'transparent' }} />
        </IconButton>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography color="text.primary" fontWeight={600} variant="h4">
          {capFirst(t('welcome', { ns: 'common' }))},
        </Typography>
        <Typography color="text.secondary" fontWeight={400} variant="h6">
          {t('login.weHaveToValidateYourIdentity')}
        </Typography>
      </Box>
      <Identification
        idType={idType}
        docTypes={loginIdTypes.data}
        errors={form.formState.errors}
        register={form.register}
        onKeyDown={handleOnKeyDown}
        onChangeType={handleOnChangeType}
      />
      <LoadingButton
        variant="contained"
        size={'large'}
        sx={{ textTransform: 'capitalize' }}
        loading={whichLogin.isPending}
        onClick={form.handleSubmit(handleOnSubmitId)}
      >
        {t('next', { ns: 'common' })}
      </LoadingButton>
      <Divider sx={{ m: 2 }} />
      <Box
        sx={{
          gap: 2,
          display: 'flex',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
        }}
      >
        <GoogleButton />
        <MicrosoftButton />
        <FacebookButton />
      </Box>
    </Box>
  )
}
