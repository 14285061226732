import { Box } from '@mui/material'
import { type ReactNode, forwardRef, memo } from 'react'
import type { Props as SimpleProps } from 'simplebar-react'
import { StyledRootScrollbar, StyledScrollbar } from './styles'

interface ScrollbarProps extends SimpleProps {
  children?: ReactNode
}

const Scrollbar = forwardRef<HTMLDivElement, ScrollbarProps>(({ children, ...props }, ref) => {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent
  const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)

  if (mobile) {
    return (
      <Box ref={ref} sx={{ overflow: 'auto' }} {...props}>
        {children}
      </Box>
    )
  }

  return (
    <StyledRootScrollbar>
      <StyledScrollbar
        scrollableNodeProps={{
          ref,
        }}
        clickOnTrack={false}
        {...props}
      >
        {children}
      </StyledScrollbar>
    </StyledRootScrollbar>
  )
})

export default memo(Scrollbar)
