import type { ThemeOptions } from '@mui/material'
import { common, grey } from '@mui/material/colors'
import { primary, text } from './color'

export const overrides: ThemeOptions = {
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          fontWeight: 500,
          borderRadius: 8,
          textTransform: 'none',
          boxShadow: 'none',
        },
        containedPrimary: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: primary.dark,
          },
          '&:active': {
            boxShadow: 'none',
            backgroundColor: primary.light,
          },
        },
        containedSecondary: {
          boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.2)',
          '&:hover': {
            backgroundColor: '#e6e6e6',
            boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.2)',
          },
          '&:active': {
            backgroundColor: '#d0d0d0',
          },
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiToggleButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          borderRadius: 6,
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          borderRadius: 6,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          overflow: 'hidden',
        },
        paper: {
          borderRadius: 8,
        },
      },
    },
    MuiMenuItem: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          height: 'fit-content',
        },
        label: {
          padding: '4px 8px',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontWeight: 600,
          color: common.white,
          backgroundColor: common.black,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          background: grey[100],
          '& .MuiOutlinedInput-root': {
            borderRadius: 8,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: 14,
          '&.Mui-error': {
            fontSize: 14,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          overflow: 'hidden',
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          background: common.white,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontSize: '1rem',
          fontWeight: 700,
          color: primary.main,
          borderBottom: '1px solid #d0d0d0',
        },
        body: {
          color: text.primary,
          borderBottom: '1px solid #d0d0d0',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          color: text.primary,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          backgroundColor: common.white,
        },
      },
    },
  },
}

export default overrides
