import { convertColorToOpacity, generateRandomColor } from '@/utils/color'
import BaseChip from '@mui/material/Chip'
import { styled } from '@mui/system'

export interface ChipProps {
  label: string
  bgColor?: string
  iconColor?: string
}

const Chip = styled(BaseChip, {
  shouldForwardProp: prop => prop !== 'bgColor' && prop !== 'iconColor',
})<ChipProps>(({ label, bgColor, iconColor }) => {
  const color = bgColor || generateRandomColor(label)
  return {
    color: convertColorToOpacity(color, 1),
    backgroundColor: convertColorToOpacity(color, 0.1),
    fontWeight: 600,
    '& .MuiChip-icon': {
      color: iconColor || 'inherit',
    },
  }
})

export default Chip
