import { client } from '@/services/client'
import type { ClientError } from '@educabot/cloud-client'
import { useMutation } from '@tanstack/react-query'
import type { AxiosResponse } from 'axios'

export interface ProspectVars {
  email: string
  name: string
  lastName: string
  countryId: number
  recaptchaToken: string
}

export default function useCreateProspect() {
  const query = useMutation<AxiosResponse<void>, ClientError, ProspectVars>({
    mutationFn: data => {
      return client.lms.prospect.create(data)
    },
  })

  return query
}
