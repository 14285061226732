import Modal from '@/components/modal'
import { LoadingButton } from '@mui/lab'
import { Typography, capitalize } from '@mui/material'
import { useEffect } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useStep } from 'usehooks-ts'
import useCreateInstitution from '../../queries/useCreateInstitution'
import useGetIdjInstitution from '../../queries/useGetIdjInstitution'
import useGetMasterSeries from '../../queries/useGetMasterSeries'
import type { InstitutionModalInputs } from '../../types/institution'
import StepFive from './stepFive'
import StepFour from './stepFour'
import StepOne from './stepOne'
import StepSix from './stepSix'
import StepThree from './stepThree'
import StepTwo from './stepTwo'

interface InstitutionModalProps {
  open: boolean
  onClose: () => void
  onCreateInstitution: () => void
}

export default function InstitutionModal({
  open,
  onClose,
  onCreateInstitution,
}: InstitutionModalProps) {
  const total = 6
  const { t } = useTranslation()

  // get IDJ information
  const {
    data: institutionData,
    error: institutionError,
    isError: isInstitutionError,
    isSuccess: isInstitutionSuccess,
    isPending: isInstitutionPending,
    mutateAsync: mutateInstitution,
  } = useGetIdjInstitution()

  // get master series
  const {
    data: masterData,
    isSuccess: isMasterSuccess,
    isPending: isMasterPending,
    mutateAsync: mutateMaster,
  } = useGetMasterSeries()

  // create institution
  const { isPending: isCreateInstitutionPending, mutateAsync: mutateAsyncCreateInstitution } =
    useCreateInstitution()

  const [currentStep, { reset: resetStep, goToPrevStep, goToNextStep }] = useStep(total)

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
    getValues,
    reset: resetForm,
  } = useForm<InstitutionModalInputs>({
    defaultValues: {
      idj: '',
      stages: [],
      address: '',
      legalName: '',
      masterSeries: [],
      seriesSelectors: {},
      academicPeriodSelectors: {},
    },
  })

  useEffect(() => {
    return () => {
      resetForm()
      resetStep()
    }
  }, [open])

  useEffect(() => {
    if (isInstitutionError) {
      setError('idj', { type: 'manual', message: institutionError.message })
    }

    if (isInstitutionSuccess) {
      const { stages, name, address } = institutionData?.data?.description ?? {}
      setValue('stages', stages.map(s => ({ name: s, checked: false })) || [])
      setValue('legalName', name)
      setValue('address', address)
    }
  }, [isInstitutionError, isInstitutionSuccess])

  useEffect(() => {
    if (isMasterSuccess) {
      const { masterSeries } = masterData?.data?.description ?? {}
      setValue('masterSeries', masterSeries || [])
    }
  }, [isMasterSuccess])

  const stepHandlers: Record<number, SubmitHandler<InstitutionModalInputs>> = {
    [1]: async data => {
      await mutateInstitution({ idjValue: data.idj, idjTypeId: 1 })
      goToNextStep()
    },
    [3]: async () => {
      const { geoType = '', geoValue = '' } = institutionData?.data?.description ?? {}
      await mutateMaster({ geoType, geoValue })
      goToNextStep()
    },
    [6]: async data => {
      const { idj, legalName, address, seriesSelectors, academicPeriodSelectors } = data

      const stages = Object.keys(seriesSelectors).flatMap(stage => {
        const current = seriesSelectors[stage]
        return current.map(c => {
          return {
            name: c.name,
            masterSeriesId: c.id,
          }
        })
      })

      const academicPeriods = Object.keys(academicPeriodSelectors).flatMap(period => {
        const current = academicPeriodSelectors[period]
        return current.map(c => {
          return {
            default: false,
            name: c.name,
            masterAcademicPeriodId: c.id,
            endsAt: c.end.format('YYYY-MM-DD'),
            startsAt: c.start.format('YYYY-MM-DD'),
          }
        })
      })

      try {
        await mutateAsyncCreateInstitution({
          address,
          name: legalName,
          idjs: [
            {
              value: idj,
              idjTypeId: 1,
              name: legalName,
            },
          ],
          stages: stages,
          academicPeriods: academicPeriods,
        })
        onClose()
        onCreateInstitution()
      } catch (error) {
        console.error(error)
      }
    },
  }

  const onSubmit: SubmitHandler<InstitutionModalInputs> = data => {
    if (stepHandlers[currentStep]) {
      stepHandlers[currentStep](data)
      return
    }

    goToNextStep()
  }

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return <StepOne register={register} errors={errors} />
      case 2:
        return <StepTwo register={register} errors={errors} />
      case 3:
        return <StepThree control={control} errors={errors} stages={getValues('stages')} />
      case 4:
        return (
          <StepFour
            //TODO move to inside
            stages={getValues('stages')
              .filter(s => s.checked)
              .map(s => s.name)}
            selectors={getValues('seriesSelectors')}
            addSelectors={value => setValue('seriesSelectors', value)}
            masterSeries={getValues('masterSeries')}
          />
        )
      case 5:
        return (
          <StepFive
            selectors={getValues('seriesSelectors')}
            academicPeriodSelectors={getValues('academicPeriodSelectors')}
            addAcademicPeriodSelectors={value => setValue('academicPeriodSelectors', value)}
          />
        )
      case 6: {
        const values = getValues()
        return (
          <StepSix
            idj={values.idj}
            name={values.legalName}
            address={values.address}
            seriesSelectors={values.seriesSelectors}
            academicPeriodSelectors={values.academicPeriodSelectors}
          />
        )
      }
      default:
        return null
    }
  }

  return (
    <Modal.Modal open={open} onClose={onClose}>
      <Modal.Content size="large">
        {currentStep > 1 && <Modal.Button position="left" kind="back" onClick={goToPrevStep} />}
        <Modal.Header />
        <Modal.Body sx={{ overflow: 'auto' }}>{renderStep()}</Modal.Body>
        <Modal.Footer sx={{ justifyContent: 'space-between' }}>
          <Typography fontSize={t => t.typography.pxToRem(16)} color="text.primary">
            {currentStep} de {total}
          </Typography>
          <LoadingButton
            loading={isMasterPending || isInstitutionPending || isCreateInstitutionPending}
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            {currentStep < total ? capitalize(t('common:next')) : capitalize(t('common:send'))}
          </LoadingButton>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Modal>
  )
}
