import { Box, Typography } from '@mui/material'

export default function SettingsPage() {
  return (
    <Box sx={{ width: '100%', height: '100%', overflow: 'auto' }}>
      <Box
        sx={{
          width: '100%',
          display: 'grid',
          gridTemplateRows: '75px 75px 1fr',
          p: {
            xs: 2,
            sm: 2,
            md: 6,
            lg: 6,
            xl: 6,
          },
          pt: {
            xs: 4,
            sm: 4,
            md: 2,
            lg: 2,
            xl: 2,
          },
        }}
      >
        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(1, 1fr)' }}>
          <Box sx={{ alignSelf: 'center' }}>
            <Typography variant="h5" fontWeight={500}>
              Configuraciónes
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} />
        <Box
          sx={{
            pt: {
              xs: 2,
              sm: 2,
              md: 2,
              lg: 2,
              xl: 2,
            },
            height: '100%',
          }}
        >
          <Box
            sx={{
              height: '100%',
              display: 'grid',
              gap: 4,
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}
