import { createTheme } from '@mui/material'
import { primary, secondary, text } from './color'
import overrides from './overrides'

const theme = createTheme({
  ...overrides,
  typography: {
    fontFamily: 'Lato, sans-serif',
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
  palette: {
    mode: 'dark',
    divider: '#3e3b4e',
    action: {
      active: '#ffffff',
      activatedOpacity: 0.24,
      hover: '#3e3b4e',
      hoverOpacity: 0.08,
      selected: '#4a4a6a',
      selectedOpacity: 0.16,
      disabled: '#6f6b7d',
      disabledOpacity: 0.4,
      disabledBackground: '#3e3b4e',
      focus: '#5f5c7a',
      focusOpacity: 0.12,
    },
    background: {
      default: '#292431',
      paper: '#312b3b',
    },
    text: {
      primary: text.primary,
      secondary: text.secondary,
    },
    primary: {
      light: primary.light,
      main: primary.main,
      dark: primary.dark,
    },
    secondary: {
      light: secondary.light,
      main: secondary.main,
      dark: secondary.dark,
    },
    error: {
      main: '#ff2c3d',
    },
    warning: {
      main: '#F45C3A',
    },
    info: {
      main: '#3e96d1',
    },
    success: {
      main: '#019f34',
    },
  },
})

export default theme
