import Authenticated from '@/modules/auth/guard/authenticated'
import LoginPage from '@/pages/login'
import IdentificationPage from '@/pages/login/identification'
import PasswordPage from '@/pages/login/password'
import { Suspense } from 'react'
import { Navigate, Outlet, type RouteObject } from 'react-router-dom'

const routes: RouteObject = {
  path: 'login',
  element: (
    <Authenticated redirect="/home/institutions">
      <Suspense>
        <Outlet />
      </Suspense>
    </Authenticated>
  ),
  children: [
    {
      path: '*',
      element: <LoginPage />,
      children: [
        {
          path: 'id/:id?/:idType?',
          element: <IdentificationPage />,
        },
        {
          path: 'password/:id?/:idType?',
          element: <PasswordPage />,
        },
        {
          path: '*',
          element: <Navigate to="id" replace />,
        },
      ],
    },
    {
      path: '*',
      element: <Navigate to="login" replace />,
    },
  ],
}

export default routes
