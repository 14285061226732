import Chip from '@/components/chip'
import SkeletonTableRows from '@/components/table/skeletonTableRows'
import { useApp } from '@/modules/app/state/useApp'
import { capFirst } from '@/utils/text'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import { common } from '@mui/material/colors'
import { useState } from 'react'
import usePaginateGetUsers from '../queries/usePaginateGetUsers'

const identificationTypes = [
  { id: 1, name: 'DNI', country: { id: 1, name: 'Argentina' } },
  { id: 2, name: 'DNI', country: { id: 2, name: 'Perú' } },
  { id: 3, name: 'CC', country: { id: 3, name: 'Colombia' } },
  { id: 4, name: 'CURP', country: { id: 4, name: 'México' } },
  { id: 5, name: 'CIC', country: { id: 5, name: 'Paraguay' } },
  { id: 6, name: 'CI', country: { id: 6, name: 'Uruguay' } },
  { id: 7, name: 'RUN', country: { id: 7, name: 'Chile' } },
  { id: 8, name: 'MINEDUC', country: { id: 8, name: 'Guatemala' } },
  { id: 9, name: 'CI', country: { id: 9, name: 'Bolivia' } },
  { id: 10, name: 'CI', country: { id: 10, name: 'Ecuador' } },
  { id: 11, name: 'CIE', country: { id: 11, name: 'República Dominicana' } },
  { id: 13, name: 'DPI', country: { id: 8, name: 'Guatemala' } },
  { id: 14, name: 'TDI', country: { id: 12, name: 'Honduras' } },
  { id: 15, name: 'CE', country: { id: 3, name: 'Colombia' } },
  { id: 16, name: 'UKR', country: { id: 13, name: 'Ucrania' } },
  { id: 17, name: 'AC', country: { id: 3, name: 'Colombia' } },
  { id: 18, name: 'Pasaporte', country: null },
  { id: 19, name: 'CI', country: { id: 14, name: 'Venezuela' } },
  { id: 21, name: 'DNI', country: { id: 16, name: 'España' } },
  { id: 22, name: 'google', country: null },
  { id: 23, name: 'CHIN', country: { id: 18, name: 'China' } },
  { id: 24, name: 'KOR', country: { id: 19, name: 'Korea' } },
  { id: 25, name: 'BRA', country: { id: 20, name: 'Brasil' } },
  { id: 26, name: 'microsoft', country: null },
  { id: 27, name: 'facebook', country: null },
]

export default function UsersTable() {
  const rowsPerPage = 12
  const { isDarkMode } = useApp()
  const [page, setPage] = useState(0)
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null)
  const { data: users, isFetching } = usePaginateGetUsers('principal', page, rowsPerPage, 1)

  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(e.currentTarget)
  }

  const handleOnClose = () => {
    setAnchorEl(null)
  }

  const handleChangePage = (_e: unknown, newPage: number) => {
    setPage(newPage)
  }

  //TODO translate this
  const labelDisplayedRows = ({
    from,
    to,
    count,
  }: {
    from: number
    to: number
    count: number
  }) => {
    return `${from}–${to} de ${count !== -1 ? count : `más de ${to}`}`
  }

  return (
    <Box
      sx={{
        height: '100%',
        display: 'grid',
        gridTemplateColumns: {
          sm: '1fr',
          md: '1fr',
          lg: '1fr',
        },
        gap: {
          xs: 2,
          sm: 2,
          md: 4,
          lg: 4,
        },
      }}
    >
      <Paper
        sx={{
          pt: 1,
          pl: 2,
          pr: 2,
          pb: 0,
          width: {
            xs: 'max-content',
            sm: 'max-content',
            md: '100%',
            lg: '100%',
            xl: '100%',
          },
          border: 1,
          display: 'flex',
          borderColor: 'divider',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Nombre</TableCell>
                <TableCell>Apellido</TableCell>
                <TableCell>Tipo de documento</TableCell>
                <TableCell>Identificación</TableCell>
                <TableCell>Mail</TableCell>
                <TableCell>Perfiles</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching ? (
                <SkeletonTableRows rowsPerPage={rowsPerPage} cells={8} />
              ) : (
                users?.list.map(user => (
                  <TableRow key={user.id}>
                    <TableCell>{user.id}</TableCell>
                    <TableCell>{user.name}</TableCell>
                    <TableCell>{user.lastName}</TableCell>
                    <TableCell>
                      {identificationTypes.find(i => user.identification?.typeId === i.id)?.name ??
                        '-'}
                    </TableCell>
                    <TableCell>{user.identification?.value ?? '-'}</TableCell>
                    <TableCell>{user.emailIdentification?.value ?? '-'}</TableCell>
                    <TableCell>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {user.profiles
                          ?.slice(0, 4)
                          .map((p, i) =>
                            i < 3 ? (
                              <Chip key={p.id} aria-label={p.name} label={capFirst(p.name)} />
                            ) : user.profiles ? (
                              <Chip
                                key="total"
                                aria-label={'total'}
                                variant={isDarkMode ? 'outlined' : 'filled'}
                                bgColor={common.white}
                                label={`+${user.profiles?.length - 3}`}
                              />
                            ) : null,
                          )}
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        aria-label="options"
                        onClick={handleOnClick}
                        sx={{
                          width: 38,
                          height: 38,
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        elevation={2}
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleOnClose}
                      >
                        <MenuItem onClick={handleOnClose}>Editar</MenuItem>
                        <MenuItem onClick={handleOnClose}>Eliminar</MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          page={page}
          component="div"
          rowsPerPageOptions={[]}
          aria-label="paginación"
          rowsPerPage={rowsPerPage}
          count={-1}
          onPageChange={handleChangePage}
          disabled={isFetching}
          labelDisplayedRows={labelDisplayedRows}
        />
      </Paper>
    </Box>
  )
}
