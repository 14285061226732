import { lms } from '@/services/client'
import { useQuery } from '@tanstack/react-query'

export default function useGetPasswordTypes() {
  const query = useQuery({
    queryKey: ['get_password_types'],
    queryFn: lms.login.getPasswordTypes,
    select: ({ data }) => data.description,
  })

  return query
}
