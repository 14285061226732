import EditIcon from '@mui/icons-material/Edit'
import { Box, FormControl, FormLabel, TextField, Typography } from '@mui/material'
import { Button, InputAdornment } from '@mui/material'
import { useState } from 'react'
import type { FieldErrors, UseFormRegister } from 'react-hook-form'
import type { InstitutionModalInputs } from '../../types/institution'

interface StepTwoProps {
  register: UseFormRegister<InstitutionModalInputs>
  errors: FieldErrors<InstitutionModalInputs>
}

export default function StepTwo({ errors, register }: StepTwoProps) {
  const [isEditingName, setIsEditingName] = useState(false)
  const [isEditingAddress, setIsEditingAddress] = useState(false)

  const handleToggleEdit = (field: 'name' | 'address') => {
    if (field === 'name') {
      setIsEditingName(!isEditingName)
      return
    }

    setIsEditingAddress(!isEditingAddress)
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box mb={4}>
        <Typography mb={1} variant="h5">
          Verificá que los datos sean correctos.
        </Typography>
        <Typography fontSize={t => t.typography.pxToRem(16)} color="text.secondary">
          Estos son los datos que legalmente se encuentran vinculados al identificador que
          ingresaste.
        </Typography>
      </Box>
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', mb: 2 }}>
        <FormControl required error={!!errors.legalName?.message}>
          <FormLabel sx={{ mb: 1 }}>Nombre legal</FormLabel>
          <TextField
            placeholder="Nombre"
            disabled={!isEditingName}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    variant="text"
                    onClick={() => handleToggleEdit('name')}
                    endIcon={<EditIcon />}
                    sx={{ ml: 2 }}
                  >
                    {isEditingName ? 'Guardar' : 'Editar'}
                  </Button>
                </InputAdornment>
              ),
            }}
            error={!!errors.legalName?.message}
            helperText={errors.legalName?.message}
            {...register('legalName', {
              required: 'Este campo es requerido',
            })}
          />
        </FormControl>
      </Box>
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', mb: 2 }}>
        <FormControl required error={!!errors.address?.message}>
          <FormLabel sx={{ mb: 1 }}>Dirección</FormLabel>
          <TextField
            placeholder="Dirección"
            disabled={!isEditingAddress}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    variant="text"
                    onClick={() => handleToggleEdit('address')}
                    endIcon={<EditIcon />}
                    sx={{ ml: 2 }}
                  >
                    {isEditingAddress ? 'Guardar' : 'Editar'}
                  </Button>
                </InputAdornment>
              ),
            }}
            error={!!errors.address?.message}
            helperText={errors.address?.message}
            {...register('address', {
              required: 'Este campo es requerido',
            })}
          />
        </FormControl>
      </Box>
    </Box>
  )
}
