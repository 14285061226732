import env from '@/config/env'
import useGoogleAuthUrl from '@/hooks/useGoogleAuthUrl'
import { Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

export default function LoginButton() {
  const { t } = useTranslation()
  const url = useGoogleAuthUrl({
    clientId: env.GOOGLE_AUTH_API_CLIENT_ID,
    redirectUri: env.GOOGLE_AUTH_API_REDIRECT_URI,
  })

  const handleOnClick = () => {
    window.location.href = url
  }

  return (
    <Button
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        p: 2,
        gap: 2,
        border: 1,
        borderRadius: 2,
        boxShadow: 'none',
        borderColor: 'divider',
        justifyContent: 'space-between',
        '&:hover': {
          boxShadow: 'none',
        },
      }}
      onClick={handleOnClick}
    >
      <Typography color="text.primary" fontWeight={600} fontSize={13} textTransform={'none'}>
        {t('login.continueWith', { agent: 'Google' })}
      </Typography>
      <img
        style={{ width: 24, height: 24, alignSelf: 'center' }}
        src="https://storage.googleapis.com/educabot-aec-cdn-1/assets/google_logo.webp"
        alt="Google logo"
      />
    </Button>
  )
}
