import type { IWindow } from './types'

const env = (window as IWindow)._env_ || {}

export const ENV = env.WEBAPP_ENV ?? 'local'
export const BASE_URL = env.WEBAPP_BASE_URL ?? ''
export const ASSETS_CDN_URL = env.WEBAPP_ASSETS_CDN_URL ?? ''

export const CLOUDFLARE_WORKER_AUTH_PROVIDER_URL =
  env.WEBAPP_CLOUDFLARE_WORKER_AUTH_PROVIDER_URL ?? ''
export const CLOUDFLARE_WORKER_COUNTRY_DETECTOR_URL =
  env.WEBAPP_CLOUDFLARE_WORKER_COUNTRY_DETECTOR_URL ?? ''
export const CLOUDFLARE_WORKER_EMAIL_PROVIDER_DETECTOR_URL =
  env.WEBAPP_CLOUDFLARE_WORKER_EMAIL_PROVIDER_DETECTOR_URL ?? ''

export const GOOGLE_RECAPTCHA_SITE_ID = env.WEBAPP_GOOGLE_RECAPTCHA_SITE_ID ?? ''
export const GOOGLE_AUTH_API_CLIENT_ID = env.WEBAPP_GOOGLE_AUTH_API_CLIENT_ID ?? ''
export const GOOGLE_AUTH_API_REDIRECT_URI = env.WEBAPP_GOOGLE_AUTH_API_REDIRECT_URI ?? ''
export const FACEBOOK_AUTH_API_CLIENT_ID = env.WEBAPP_FACEBOOK_AUTH_API_CLIENT_ID ?? ''
export const MICROSOFT_AUTH_API_CLIENT_ID = env.WEBAPP_MICROSOFT_AUTH_API_CLIENT_ID ?? ''
export const MICROSOFT_AUTH_API_REDIRECT_URI = env.WEBAPP_MICROSOFT_AUTH_API_REDIRECT_URI ?? ''
