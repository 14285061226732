import InstitutionModal from '@/modules/home/institutions/components/institution/institutionModal'
import InstitutionsCards from '@/modules/home/institutions/components/institutionsCards'
import InstitutionsTable from '@/modules/home/institutions/components/institutionsTable'
import SearchInstitution from '@/modules/home/institutions/components/searchInstitution'
import ToggleViewButton from '@/modules/home/institutions/components/toggleViewButton'
import AddIcon from '@mui/icons-material/Add'
import { Box, Button, type Theme, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'
import { useBoolean, useDebounceValue } from 'usehooks-ts'

export default function InstitutionsPage() {
  const theme = useTheme()
  const [view, setView] = useState('grid')
  const [searchQuery, setSearchQuery] = useState('')
  const { value: isModalOpen, setTrue: openModal, setFalse: closeModal } = useBoolean(false)
  const [searchQueryDebounced] = useDebounceValue(searchQuery, 500)
  const [refresh, setRefresh] = useState(false)
  const isMobile = useMediaQuery<Theme>(t => t.breakpoints.down('md'))

  const handleOnViewChange = (target: string) => {
    setView(target)
  }

  const handleOnChangeSearch = (value: string) => {
    setSearchQuery(value)
  }

  const handleOnCreateInstitution = () => {
    setRefresh(!refresh)
  }

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <InstitutionModal
        open={isModalOpen}
        onClose={closeModal}
        onCreateInstitution={handleOnCreateInstitution}
      />
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'grid',
          gridTemplateRows: '75px 75px 1fr',
          p: {
            xs: 2,
            sm: 2,
            md: 6,
            lg: 6,
            xl: 6,
          },
          pt: {
            xs: 4,
            sm: 4,
            md: 2,
            lg: 2,
            xl: 2,
          },
        }}
      >
        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' }}>
          <Box sx={{ alignSelf: 'center' }}>
            <Typography variant="h5" fontWeight={500}>
              Instituciones
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignSelf: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              size="large"
              variant="contained"
              aria-label="Nueva institución"
              color={theme.palette.mode === 'dark' ? 'secondary' : 'primary'}
              startIcon={<AddIcon />}
              onClick={openModal}
            >
              {isMobile ? 'Nueva' : 'Nueva institución'}
            </Button>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <SearchInstitution onChange={handleOnChangeSearch} />
          <ToggleViewButton
            view={view}
            sx={{ alignSelf: 'center' }}
            onChange={handleOnViewChange}
          />
        </Box>
        <Box
          sx={{
            pt: {
              xs: 2,
              sm: 2,
              md: 2,
              lg: 2,
              xl: 2,
            },
            pb: {
              xs: 2,
              sm: 2,
              md: 2,
              lg: 2,
              xl: 2,
            },
            height: '100%',
            overflow: 'auto',
          }}
        >
          {view === 'table' ? (
            <InstitutionsTable searchQuery={searchQueryDebounced} refresh={refresh} />
          ) : (
            <InstitutionsCards searchQuery={searchQueryDebounced} refresh={refresh} />
          )}
        </Box>
      </Box>
    </Box>
  )
}
