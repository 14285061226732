import type { ThemeOptions } from '@mui/material'
import { common } from '@mui/material/colors'
import { primary, secondary, text } from './color'

const overrides: ThemeOptions = {
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          fontWeight: 500,
          borderRadius: 8,
          textTransform: 'none',
        },
        containedPrimary: {
          '&:hover': {
            backgroundColor: primary.dark,
          },
          '&:active': {
            backgroundColor: primary.light,
          },
        },
        containedSecondary: {
          '&:hover': {
            backgroundColor: secondary.dark,
          },
          '&:active': {
            backgroundColor: secondary.light,
          },
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiToggleButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          borderRadius: 6,
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          borderRadius: 6,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          overflow: 'hidden',
        },
        paper: {
          borderRadius: 8,
        },
      },
    },
    MuiMenuItem: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          '&:hover': {
            background: '#474553',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          height: 'fit-content',
        },
        label: {
          padding: '4px 8px',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontWeight: 600,
          color: common.black,
          backgroundColor: common.white,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          background: '#312b3b',
          '& .MuiOutlinedInput-root': {
            borderRadius: 8,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: 14,
          '&.Mui-error': {
            fontSize: 14,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          overflow: 'hidden',
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontSize: '1rem',
          fontWeight: 700,
          borderBottom: '1px solid #292431',
        },
        body: {
          borderBottom: '1px solid #292431',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          color: text.primary,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          backgroundColor: common.white,
        },
      },
    },
  },
}

export default overrides
