import ViewListIcon from '@mui/icons-material/ViewList'
import ViewModuleIcon from '@mui/icons-material/ViewModule'
import { type SxProps, type Theme, Tooltip } from '@mui/material'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import type React from 'react'

export interface ToggleViewButtonProps {
  sx?: SxProps<Theme>
  view: string
  onChange?: (target: string) => void
}

export default function ToggleViewButton({ sx, view, onChange }: ToggleViewButtonProps) {
  const handleOnChangeView = (_e: React.MouseEvent<HTMLElement>, target: string | null) => {
    if (target) onChange?.(target)
  }

  return (
    <ToggleButtonGroup
      sx={sx}
      exclusive
      value={view}
      size="medium"
      aria-label="cambiar modo de vista"
      onChange={handleOnChangeView}
    >
      <Tooltip title="Lista" placement="bottom">
        <ToggleButton value="table" aria-label="vista tabla">
          <ViewListIcon />
        </ToggleButton>
      </Tooltip>
      <Tooltip title="Cuadrícula" placement="bottom">
        <ToggleButton value="grid" aria-label="vista cuadricula">
          <ViewModuleIcon />
        </ToggleButton>
      </Tooltip>
    </ToggleButtonGroup>
  )
}
