import { Wrapper } from '@/modules/app/wrapper'
import Protected from '@/modules/auth/guard/protected'
import HomePage from '@/pages/home'
import InstitutionsPage from '@/pages/home/institutions'
import SettingsPage from '@/pages/home/settings'
import UsersPage from '@/pages/home/users'
import { Suspense } from 'react'
import { Navigate, Outlet, type RouteObject } from 'react-router-dom'

const routes: RouteObject = {
  path: 'home',
  element: (
    <Protected>
      <Suspense>
        <Wrapper>
          <Outlet />
        </Wrapper>
      </Suspense>
    </Protected>
  ),
  children: [
    {
      path: '*',
      element: <HomePage />,
      children: [
        {
          path: 'institutions',
          element: <InstitutionsPage />,
        },
        {
          path: 'users',
          element: <UsersPage />,
        },
        {
          path: 'settings',
          element: <SettingsPage />,
        },
        {
          path: '*',
          element: <Navigate to="institutions" replace />,
        },
      ],
    },
    {
      path: '',
      element: <Navigate to="home" replace />,
    },
  ],
}

export default routes
