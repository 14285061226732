import { org } from '@/services/client'
import type { ClientError } from '@educabot/cloud-client'
import type { GetV1 } from '@educabot/cloud-client/lib/org/idj-institution/get'
import { useMutation } from '@tanstack/react-query'
import type { AxiosResponse } from 'axios'

export interface GetIdjInstitutionVars {
  idjValue: string
  idjTypeId: number
}

export default function useGetIdjInstitution() {
  const query = useMutation<
    AxiosResponse<GetV1.GetIdjInstitutionResponse>,
    ClientError,
    GetIdjInstitutionVars
  >({
    mutationFn: d => org.idjInstitution.get(d),
  })

  return query
}
