import { lms } from '@/services/client'
import { useQuery } from '@tanstack/react-query'

export const initialData = {
  types: [
    {
      country: {
        flag: 'https://storage.googleapis.com/educabot-aec-cdn-1/identification-types/FlagArgentina.svg',
        id: 1,
        name: 'Argentina',
      },
      hidden: false,
      id: 1,
      inputType: 'number',
      max: 8,
      min: 7,
      name: 'DNI',
      icon: '',
    },
    {
      country: {
        flag: 'https://storage.googleapis.com/educabot-aec-cdn-1/identification-types/FlagPeru.svg',
        id: 2,
        name: 'Perú',
      },
      hidden: false,
      id: 2,
      inputType: 'number',
      max: 0,
      min: 0,
      name: 'DNI',
      icon: '',
    },
    {
      country: {
        flag: 'https://storage.googleapis.com/educabot-aec-cdn-1/identification-types/FlagColombia.svg',
        id: 3,
        name: 'Colombia',
      },
      hidden: false,
      id: 3,
      inputType: 'number',
      max: 10,
      min: 8,
      name: 'CC',
      icon: '',
    },
    {
      country: {
        flag: 'https://storage.googleapis.com/educabot-aec-cdn-1/identification-types/FlagM%C3%A9xico.svg',
        id: 4,
        name: 'México',
      },
      hidden: false,
      id: 4,
      inputType: 'text',
      max: 18,
      min: 15,
      name: 'CURP',
      icon: '',
    },
    {
      country: {
        flag: 'https://storage.googleapis.com/educabot-aec-cdn-1/identification-types/FlagParaguay.svg',
        id: 5,
        name: 'Paraguay',
      },
      hidden: false,
      id: 5,
      inputType: 'number',
      max: 7,
      min: 7,
      name: 'CIC',
      icon: '',
    },
    {
      country: {
        flag: 'https://storage.googleapis.com/educabot-aec-cdn-1/identification-types/FlagUruguay.svg',
        id: 6,
        name: 'Uruguay',
      },
      hidden: false,
      id: 6,
      inputType: 'number',
      max: 8,
      min: 8,
      name: 'CI',
      icon: '',
    },
    {
      country: {
        flag: 'https://storage.googleapis.com/educabot-aec-cdn-1/identification-types/FlagChile.svg',
        id: 7,
        name: 'Chile',
      },
      hidden: false,
      id: 7,
      inputType: 'text',
      max: 9,
      min: 9,
      name: 'RUN',
      icon: '',
    },
    {
      country: {
        flag: 'https://storage.googleapis.com/educabot-aec-cdn-1/identification-types/FlagGuatemala.svg',
        id: 8,
        name: 'Guatemala',
      },
      hidden: false,
      id: 8,
      inputType: 'text',
      max: 7,
      min: 7,
      name: 'MINEDUC',
      icon: '',
    },
    {
      country: {
        flag: 'https://storage.googleapis.com/educabot-aec-cdn-1/identification-types/FlagBolivia.svg',
        id: 9,
        name: 'Bolivia',
      },
      hidden: false,
      id: 9,
      inputType: 'text',
      max: 9,
      min: 9,
      name: 'CI',
      icon: '',
    },
    {
      country: {
        flag: 'https://storage.googleapis.com/educabot-aec-cdn-1/identification-types/FlagEcuador.svg',
        id: 10,
        name: 'Ecuador',
      },
      hidden: false,
      id: 10,
      inputType: 'number',
      max: 10,
      min: 10,
      name: 'CI',
      icon: '',
    },
    {
      country: {
        flag: 'https://storage.googleapis.com/educabot-aec-cdn-1/identification-types/FlagRep%C3%BAblica%20Dominicana.svg',
        id: 11,
        name: 'República Dominicana',
      },
      hidden: false,
      id: 11,
      inputType: 'number',
      max: 11,
      min: 11,
      name: 'CIE',
      icon: '',
    },
    {
      country: null,
      hidden: false,
      id: 12,
      inputType: 'email',
      max: 256,
      min: 0,
      name: 'email',
      icon: 'https://storage.googleapis.com/educabot-aec-cdn-1/identification-types/Email.svg',
    },
    {
      country: {
        flag: 'https://storage.googleapis.com/educabot-aec-cdn-1/identification-types/FlagGuatemala.svg',
        id: 8,
        name: 'Guatemala',
      },
      hidden: false,
      id: 13,
      inputType: 'number',
      max: 13,
      min: 13,
      name: 'DPI',
      icon: '',
    },
    {
      country: {
        flag: 'https://storage.googleapis.com/educabot-aec-cdn-1/identification-types/FlagHonduras.svg',
        id: 12,
        name: 'Honduras',
      },
      hidden: false,
      id: 14,
      inputType: 'number',
      max: 13,
      min: 12,
      name: 'TDI',
      icon: '',
    },
    {
      country: {
        flag: 'https://storage.googleapis.com/educabot-aec-cdn-1/identification-types/FlagColombia.svg',
        id: 3,
        name: 'Colombia',
      },
      hidden: false,
      id: 15,
      inputType: 'number',
      max: 6,
      min: 6,
      name: 'CE',
      icon: '',
    },
    {
      country: {
        flag: 'https://storage.googleapis.com/educabot-aec-cdn-1/identification-types/FlagUcrania.svg',
        id: 13,
        name: 'Ucrania',
      },
      hidden: false,
      id: 16,
      inputType: 'text',
      max: 8,
      min: 8,
      name: 'UKR',
      icon: '',
    },
    {
      country: {
        flag: 'https://storage.googleapis.com/educabot-aec-cdn-1/identification-types/FlagColombia.svg',
        id: 3,
        name: 'Colombia',
      },
      hidden: false,
      id: 17,
      inputType: 'number',
      max: 7,
      min: 7,
      name: 'AC',
      icon: '',
    },
    {
      country: null,
      hidden: false,
      id: 18,
      inputType: 'text',
      max: 9,
      min: 6,
      name: 'Pasaporte',
      icon: 'https://storage.googleapis.com/educabot-aec-cdn-1/identification-types/PASSPORT.svg',
    },
    {
      country: {
        flag: 'https://storage.googleapis.com/educabot-aec-cdn-1/identification-types/FlagVenezuela.svg',
        id: 14,
        name: 'Venezuela',
      },
      hidden: false,
      id: 19,
      inputType: 'number',
      max: 8,
      min: 7,
      name: 'CI',
      icon: '',
    },
    {
      country: {
        flag: 'https://storage.googleapis.com/educabot-aec-cdn-1/identification-types/FlagEspa%C3%B1a.svg',
        id: 16,
        name: 'España',
      },
      hidden: false,
      id: 21,
      inputType: 'text',
      max: 9,
      min: 9,
      name: 'DNI',
      icon: '',
    },
    {
      country: {
        flag: 'https://storage.googleapis.com/educabot-aec-cdn-1/identification-types/FlagChina.svg',
        id: 18,
        name: 'China',
      },
      hidden: false,
      id: 23,
      inputType: 'string',
      max: 50,
      min: 6,
      name: 'CHIN',
      icon: '',
    },
    {
      country: {
        flag: 'https://storage.googleapis.com/educabot-aec-cdn-1/identification-types/FlagKorea.svg',
        id: 19,
        name: 'Korea',
      },
      hidden: false,
      id: 24,
      inputType: 'string',
      max: 50,
      min: 6,
      name: 'KOR',
      icon: '',
    },
    {
      country: {
        flag: 'https://storage.googleapis.com/educabot-aec-cdn-1/identification-types/FlagBrasil.svg',
        id: 20,
        name: 'Brasil',
      },
      hidden: false,
      id: 25,
      inputType: 'string',
      max: 50,
      min: 6,
      name: 'BRA',
      icon: '',
    },
  ],
}

export default function useGetLoginIdTypes() {
  const query = useQuery({
    queryKey: ['get_login_id_types'],
    queryFn: async () => {
      const resp = await lms.login.getIdTypes()
      return resp.data.description
    },
    initialData,
    // ignore id types of microsoft, google and facebook
    select: data => data.types.filter(d => d.id !== 22 && d.id !== 26 && d.id !== 27),
  })

  return query
}
