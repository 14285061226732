import type { ModalProps } from './modal'
import type { ModalBodyProps } from './modal.body'
import type { ModalButtonProps } from './modal.button'
import type { ModalContentProps } from './modal.content'
import type { ModalFooterProps } from './modal.footer'
import type { ModalHeaderProps } from './modal.header'

import { Modal } from './modal'
import { ModalBody } from './modal.body'
import { ModalButton } from './modal.button'
import { ModalContent } from './modal.content'
import { ModalFooter } from './modal.footer'
import { ModalHeader } from './modal.header'

export type {
  ModalProps,
  ModalHeaderProps,
  ModalButtonProps,
  ModalBodyProps,
  ModalFooterProps,
  ModalContentProps,
}

export default {
  Modal: Modal,
  Header: ModalHeader,
  Body: ModalBody,
  Button: ModalButton,
  Content: ModalContent,
  Footer: ModalFooter,
}
