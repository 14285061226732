import { Box, type BoxProps } from '@mui/material'
import { Typography } from '@mui/material'
import type React from 'react'

export interface ModalHeaderProps extends BoxProps {
  children?: React.ReactNode
}

export const ModalHeader: React.FC<ModalHeaderProps> = ({ children, sx, ...props }) => {
  return (
    <Box
      component={'header'}
      sx={{
        width: '100%',
        flex: 0.1,
        p: 1,
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        ...sx,
      }}
      {...props}
    >
      <Typography
        sx={{
          alignSelf: 'center',
          color: 'currentcolor',
          fontSize: t => t.typography.pxToRem(16),
          fontFamily: t => t.typography.fontFamily,
        }}
      >
        {children}
      </Typography>
    </Box>
  )
}
