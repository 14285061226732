import { Box, Typography } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs, { type Dayjs } from 'dayjs'
import { useEffect } from 'react'
import type { AcademicPeriodSelector, SeriesSelector } from '../../types/institution'

interface StepFiveProps {
  selectors: SeriesSelector
  academicPeriodSelectors: AcademicPeriodSelector
  addAcademicPeriodSelectors: (selectors: AcademicPeriodSelector) => void
}

export default function StepFive({
  selectors,
  academicPeriodSelectors,
  addAcademicPeriodSelectors,
}: StepFiveProps) {
  useEffect(() => {
    const initAcademicPeriod: AcademicPeriodSelector = {}
    for (const stageId in selectors) {
      initAcademicPeriod[stageId] = []
      for (const [index, serie] of selectors[stageId].entries()) {
        const current = academicPeriodSelectors[stageId]?.[index] || {}

        const latestPeriod = serie.academicPeriods.reduce((mostRecent, period) => {
          return dayjs(period.startDate).isAfter(dayjs(mostRecent.startDate)) ? period : mostRecent
        })

        initAcademicPeriod[stageId].push({
          ...current,
          id: serie.id,
          name: serie.name,
          start: current.start || dayjs(latestPeriod.startDate),
          minStart: current.minStart || dayjs(latestPeriod.minStartDate),
          maxStart: current.maxStart || dayjs(latestPeriod.maxStartDate),
          end: current.end || dayjs(latestPeriod.endDate),
          minEnd: current.minEnd || dayjs(latestPeriod.minEndDate),
          maxEnd: current.maxEnd || dayjs(latestPeriod.maxEndDate),
        })
      }
    }

    addAcademicPeriodSelectors(initAcademicPeriod)
  }, [])

  const handleDateChange = (
    stage: string,
    index: number,
    date: Dayjs | null,
    type: 'start' | 'end',
  ) => {
    const updated = {
      ...academicPeriodSelectors,
      [stage]: academicPeriodSelectors[stage].map((period, idx) => {
        return idx === index
          ? {
              ...period,
              [type]: date,
            }
          : period
      }),
    }

    addAcademicPeriodSelectors(updated)
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box mb={4}>
        <Typography mb={1} variant="h5">
          Configurá el ciclo lectivo para cada nivel.
        </Typography>
        <Typography fontSize={t => t.typography.pxToRem(16)} color="text.secondary">
          Estas son las fechas de inicio y fin recomendadas según la Agenda Educativa. Podés
          modificarlas dentro de un rango estipulado.
        </Typography>
      </Box>
      {Object.keys(selectors).map(stage => (
        <Box key={stage} sx={{ display: 'flex', flexDirection: 'column' }}>
          {academicPeriodSelectors[stage]?.map((s, index) => (
            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
            <LocalizationProvider key={index} dateAdapter={AdapterDayjs}>
              <Typography
                mb={2}
                color="primary.main"
                fontWeight={600}
                fontSize={t => t.typography.pxToRem(16)}
              >
                {s.name}
              </Typography>
              <Box sx={{ display: 'flex', gap: 2, mb: 4 }}>
                <DatePicker
                  label="Fecha de inicio"
                  value={s.start}
                  minDate={s.minStart}
                  maxDate={s.maxStart}
                  onChange={date => handleDateChange(stage, index, date, 'start')}
                />
                <DatePicker
                  label="Fecha de fin"
                  value={s.end}
                  minDate={s.minEnd}
                  maxDate={s.maxEnd}
                  onChange={date => handleDateChange(stage, index, date, 'end')}
                />
              </Box>
            </LocalizationProvider>
          ))}
        </Box>
      ))}
    </Box>
  )
}
