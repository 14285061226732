import type { GetInfoResponse } from '@educabot/cloud-client/lib/lms/user/info/get/get'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export interface UseApp {
  user: GetInfoResponse['description']
  setUser: (user: GetInfoResponse['description']) => void
  theme: 'light' | 'dark'
  isDarkMode: boolean
  sidePanelOpen: boolean
  sidePanelCollapsed: boolean
  toggleSidePanel: () => void
  openSidePanel: () => void
  closeSidePanel: () => void
  toggleTheme: () => void
}

export const useApp = create<UseApp>()(
  persist(
    set => ({
      theme: 'light',
      isDarkMode: false,
      sidePanelOpen: false,
      sidePanelCollapsed: true,
      user: {} as GetInfoResponse['description'],
      // actions
      openSidePanel: () => set(() => ({ sidePanelOpen: true, sidePanelCollapsed: false })),
      closeSidePanel: () => set(() => ({ sidePanelOpen: false })),
      toggleSidePanel: () => set(s => ({ sidePanelCollapsed: !s.sidePanelCollapsed })),
      setUser: (user: GetInfoResponse['description']) => {
        set({ user })
      },
      toggleTheme: () => {
        set(s => ({
          isDarkMode: !s.isDarkMode,
          theme: s.theme === 'dark' ? 'light' : 'dark',
        }))
      },
    }),
    {
      name: 'user',
      partialize: s => ({
        user: s.user,
        theme: s.theme,
        isDarkMode: s.isDarkMode,
      }),
    },
  ),
)

export const { user, setUser } = useApp.getState()
