export const primary = {
  light: '#554d7e',
  main: '#2A205E',
  dark: '#221a4b',
}

export const secondary = {
  light: '#FFFFFF',
  main: '#E0E0E0',
  dark: '#616161',
}

export const text = {
  primary: '#1A1A1A',
  secondary: '#6d7878',
}
