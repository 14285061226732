import { login } from '@/modules/auth/state/useAuth'
import { client } from '@/services/client'
import { msalInstance } from './msal'

export async function init() {
  await msalInstance.initialize()
  const result = await msalInstance.handleRedirectPromise()
  if (result === null) return

  const resp = await client.lms.login.loginWithMicrosoft({ idToken: result.idToken })
  login(resp.data.description.token)
}
