import Chip from '@/components/chip'
import SkeletonTableRows from '@/components/table/skeletonTableRows'
import { useApp } from '@/modules/app/state/useApp'
import { capFirst } from '@/utils/text'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import { Box } from '@mui/material'
import { IconButton, Menu, MenuItem } from '@mui/material'
import { common } from '@mui/material/colors'
import { useState } from 'react'
import usePaginateGetOrganizations from '../queries/usePaginateGetOrganizations'

interface InstitutionsTableProps {
  searchQuery: string
  refresh: boolean
}

export default function InstitutionsTable({ searchQuery, refresh }: InstitutionsTableProps) {
  const rowsPerPage = 12
  const [page, setPage] = useState(0)
  const { isDarkMode } = useApp()
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null)
  const { data: organizations, isFetching } = usePaginateGetOrganizations(
    refresh,
    page,
    rowsPerPage,
    searchQuery,
  )

  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(e.currentTarget)
  }

  const handleOnClose = () => {
    setAnchorEl(null)
  }

  const handleChangePage = (_e: unknown, newPage: number) => {
    setPage(newPage)
  }

  return (
    <Box
      sx={{
        height: '100%',
        display: 'grid',
        gridTemplateColumns: {
          sm: '1fr',
          md: '1fr',
          lg: '1fr',
        },
        gap: {
          xs: 2,
          sm: 2,
          md: 4,
          lg: 4,
        },
      }}
    >
      <Paper
        sx={{
          pt: 1,
          pl: 2,
          pr: 2,
          pb: 0,
          maxWidth: {
            xs: 'max-content',
            sm: 'max-content',
            md: '100%',
            lg: '100%',
            xl: '100%',
          },
          border: 1,
          display: 'flex',
          borderColor: 'divider',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Nombre</TableCell>
                <TableCell>Dirección</TableCell>
                <TableCell>IDJ</TableCell>
                <TableCell>Niveles</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching ? (
                <SkeletonTableRows rowsPerPage={rowsPerPage} cells={6} />
              ) : (
                organizations.organizations.map(org => (
                  <TableRow key={org.id}>
                    <TableCell>{org.id}</TableCell>
                    <TableCell>{org.name}</TableCell>
                    <TableCell>{org.address}</TableCell>
                    <TableCell>
                      {org.idjs.length ? org.idjs.map(i => i.value).join(', ') : '-'}
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {org.stages
                          .slice(0, 4)
                          .map((s, i) =>
                            i < 3 ? (
                              <Chip key={s.id} aria-label={s.name} label={capFirst(s.name)} />
                            ) : (
                              <Chip
                                key="total"
                                aria-label={'total'}
                                variant={isDarkMode ? 'outlined' : 'filled'}
                                bgColor={common.white}
                                label={`+${org.stages.length - 3}`}
                              />
                            ),
                          )}
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        aria-label="options"
                        onClick={handleOnClick}
                        sx={{
                          maxWidth: 38,
                          height: 38,
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        elevation={2}
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleOnClose}
                      >
                        <MenuItem onClick={handleOnClose}>Editar</MenuItem>
                        <MenuItem onClick={handleOnClose}>Eliminar</MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          page={page}
          component="div"
          rowsPerPageOptions={[]}
          aria-label="paginación"
          rowsPerPage={rowsPerPage}
          count={organizations.total}
          onPageChange={handleChangePage}
        />
      </Paper>
    </Box>
  )
}
