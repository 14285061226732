import env from '@/config/env'
import { useApp } from '@/modules/app/state/useApp'
import { Box } from '@mui/material'
import { Outlet } from 'react-router-dom'

export default function LoginPage() {
  const { isDarkMode } = useApp()
  return (
    <Box
      sx={t => ({
        width: '100%',
        height: '100%',
        display: 'grid',
        overflow: 'auto',
        bgcolor: isDarkMode ? 'background.default' : 'common.white',
        [t.breakpoints.up('lg')]: {
          gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
        },
        [t.breakpoints.down('lg')]: {
          gridTemplateRows: '20% 1fr',
        },
      })}
    >
      <Box
        sx={t => ({
          width: '100%',
          display: 'grid',
          background: 'linear-gradient(45deg, #826def24, #2A205E0A)',
          justifyContent: 'center',
          [t.breakpoints.up('lg')]: {
            gridTemplateRows: 'repeat(2, 1fr)',
          },
        })}
      >
        <img
          style={{ width: '90%', margin: 'auto' }}
          src={
            isDarkMode
              ? `${env.ASSETS_CDN_URL}/images/educabot_cloud_logo_white.svg`
              : `${env.ASSETS_CDN_URL}/images/educabot_cloud_logo.svg`
          }
          alt="Educabot cloud logo"
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          p: 4,
          mb: 4,
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignSelf: 'center',
            maxWidth: {
              xs: '100%',
              md: 460,
              sm: 460,
              lg: 460,
            },
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  )
}
