import SignUpPage from '@/pages/signup'
import RegisterPage from '@/pages/signup/register'
import RegisterSucessPage from '@/pages/signup/registerSucess'
import { Navigate, type RouteObject } from 'react-router-dom'

const routes: RouteObject = {
  path: 'sign_up',
  element: <SignUpPage />,
  children: [
    {
      path: 'register/:email?/:name?/:lastName?/:countryId?',
      element: <RegisterPage />,
    },
    {
      path: 'register_sucess/:email?/:name?/:lastName?/:countryId?',
      element: <RegisterSucessPage />,
    },
    {
      path: '',
      element: <Navigate to="register" replace />,
    },
  ],
}

export default routes
