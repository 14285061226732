import env from '@/config/env'
import { MsalProvider } from '@azure/msal-react'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { QueryClientProvider } from '@tanstack/react-query'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import { init as initMsal } from '@/modules/auth/providers/microsoft'
import { msalInstance } from '@/modules/auth/providers/microsoft/msal'
import { init as initLocales } from './locales'
import { queryClient } from './state/query'
import 'simplebar-react/dist/simplebar.min.css'
import App from './app'

console.info('Release version 🚀:', process.env.APP_RELEASE)

async function main() {
  await initMsal()
  await initLocales()

  const container = document.getElementById('root')
  if (container) {
    createRoot(container).render(
      <StrictMode>
        <QueryClientProvider client={queryClient}>
          <MsalProvider instance={msalInstance}>
            <GoogleOAuthProvider clientId={env.GOOGLE_AUTH_API_CLIENT_ID}>
              <App />
            </GoogleOAuthProvider>
          </MsalProvider>
        </QueryClientProvider>
      </StrictMode>,
    )
  }
}

main()
