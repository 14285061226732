import { lms } from '@/services/client'
import type {
  GetWhichData,
  GetWhichResponse,
} from '@educabot/cloud-client/lib/lms/login/getWhich/getWhich'
import { useMutation } from '@tanstack/react-query'
import type { AxiosError, AxiosResponse } from 'axios'

export default function useGetWhichLogin() {
  const query = useMutation<AxiosResponse<GetWhichResponse>, AxiosError, GetWhichData>({
    mutationFn: data => {
      return lms.login.getWhichV3(data)
    },
  })

  return query
}
