import Home from '@/app/router/home'
import Login from '@/app/router/login'
import SignUp from '@/app/router/signup'
import Startup from '@/app/router/startup'
import User from '@/app/router/user'
import GoogleAuth from '@/pages/auth/google'
import { Navigate, createBrowserRouter } from 'react-router-dom'

const router = createBrowserRouter(
  [
    Login,
    SignUp,
    Home,
    Startup,
    User,
    {
      path: '/auth/google/callback',
      element: <GoogleAuth />,
    },
    {
      path: '*',
      element: <Navigate to="/login/id" />,
    },
  ],
  { basename: '/app' },
)

export default router
