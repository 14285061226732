import { useApp } from '@/modules/app/state/useApp'
import { useAuth } from '@/modules/auth/state/useAuth'
import formatFullName from '@/utils/formatFullName'
import DarkModeIcon from '@mui/icons-material/DarkMode'
import LightModeIcon from '@mui/icons-material/LightMode'
import Logout from '@mui/icons-material/Logout'
import Settings from '@mui/icons-material/Settings'
import { type SvgIconProps, Typography, useTheme } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'
import ListItemIcon from '@mui/material/ListItemIcon'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { Box } from '@mui/system'
import React from 'react'
import { useNavigate } from 'react-router-dom'

interface Profile {
  id: number
  name: string
  role: string
  organizationId: number
}

interface AccountMenuItem {
  icon: JSX.Element
  text: string
  disabled?: boolean
  onClick: () => void
}

interface AccountMenuProps {
  avatar: string
  name: string
  lastName: string
  profile: Profile
  anchorEl: null | HTMLElement
  onClose: () => void
}

export default function AccountMenu({
  name,
  lastName,
  profile,
  avatar,
  anchorEl,
  onClose,
}: AccountMenuProps) {
  const theme = useTheme()
  const navigate = useNavigate()
  const { logout } = useAuth()
  const { toggleTheme, isDarkMode } = useApp()

  const items: AccountMenuItem[] = [
    {
      icon: <Settings />,
      text: 'Configuración',
      onClick: handleOnClickSettings,
    },
    {
      icon: isDarkMode ? <LightModeIcon /> : <DarkModeIcon />,
      text: isDarkMode ? 'Tema claro' : 'Tema oscuro',
      onClick: handleOnClickToggleTheme,
    },
    {
      icon: <Logout />,
      text: 'Cerrar sesión',
      onClick: handleOnClickAccount,
    },
  ]

  function handleOnClickAccount() {
    logout()
    navigate('/login/id')
    onClose()
  }

  function handleOnClickToggleTheme() {
    toggleTheme()
    onClose()
  }

  function handleOnClickSettings() {
    navigate('/user/settings')
    onClose()
  }

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      onClose={onClose}
      elevation={3}
      slotProps={{
        paper: {
          sx: {
            minWidth: 200,
            overflow: 'visible',
            mt: 1.5,
            justifyContent: 'flex-end',
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 18,
              width: 10,
              height: 10,
              bgcolor: theme.palette.mode === 'dark' ? '#413C4A' : 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
              alignSelf: 'center',
            },
          },
        },
      }}
    >
      <MenuItem onClick={onClose}>
        <Avatar src={avatar} />
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            sx={{
              textWrap: 'wrap',
              maxWidth: '180px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              flexDirection: 'row',
              fontWeight: 600,
              color: 'text.primary',
            }}
          >
            {formatFullName({ name, lastName })}
          </Typography>
          <Typography variant="caption" lineHeight={1} color="text.secondary">
            {profile.name}
          </Typography>
        </Box>
      </MenuItem>
      <Divider />
      {items.map(item => (
        <MenuItem disabled={item.disabled} key={crypto.randomUUID()} onClick={item.onClick}>
          <ListItemIcon>
            {React.cloneElement<SvgIconProps>(item.icon, {
              sx: { fontSize: 'small' },
            })}
          </ListItemIcon>
          {item.text}
        </MenuItem>
      ))}
    </Menu>
  )
}
