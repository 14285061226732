import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { FormControl } from '@mui/material'
import { Box, InputAdornment, TextField } from '@mui/material'
import { useState } from 'react'
import type { FieldErrors, UseFormRegister } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export type PasswordInputs = {
  password: string
}

export interface PasswordProps {
  errors: FieldErrors<PasswordInputs>
  register: UseFormRegister<PasswordInputs>
  onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void
}

export default function Password({ errors, register, onKeyDown }: PasswordProps) {
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)

  const handleToggleVisibility = () => {
    setVisible(v => !v)
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
      <FormControl sx={{ display: 'flex', flex: 1 }}>
        <TextField
          required
          variant="outlined"
          type={visible ? 'text' : 'password'}
          placeholder={t('login.enterYourPassword')}
          error={!!errors.password}
          helperText={errors.password?.message}
          onKeyDown={onKeyDown}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {visible ? (
                  <VisibilityIcon
                    sx={{ color: t => t.palette.action.active, cursor: 'pointer' }}
                    onClick={handleToggleVisibility}
                  />
                ) : (
                  <VisibilityOffIcon
                    sx={{ color: t => t.palette.action.active, cursor: 'pointer' }}
                    onClick={handleToggleVisibility}
                  />
                )}
              </InputAdornment>
            ),
          }}
          {...register('password', {
            required: {
              value: true,
              message: t('login.passwordRequired'),
            },
          })}
        />
      </FormControl>
    </Box>
  )
}
