import { lms } from '@/services/client'
import type { ClientError } from '@educabot/cloud-client'
import type { GetV1 } from '@educabot/cloud-client/lib/lms/organizations/get'
import { useQuery } from '@tanstack/react-query'

export default function usePaginateGetOrganizations(
  refresh: boolean,
  page: number,
  rowsPerPage: number,
  searchQuery?: string,
) {
  const query = useQuery<GetV1.GetOrganizationsResponse['description'], ClientError>({
    queryKey: ['get_organizations_paginate', refresh, page, searchQuery],
    queryFn: async () => {
      const offset = page * rowsPerPage
      const data = await lms.organizations.get({ offset, limit: rowsPerPage, search: searchQuery })
      return data.data.description
    },
    initialData: {
      total: 0,
      organizations: [],
    },
  })

  return query
}
