import { org } from '@/services/client'
import type { ClientError } from '@educabot/cloud-client'
import type { CreateV1 } from '@educabot/cloud-client/lib/org/pricipal/organizations/create'
import { useMutation } from '@tanstack/react-query'
import type { AxiosResponse } from 'axios'

export interface CreateInstitutionVars {
  address: string
  name: string
  idjs: CreateV1.Idj[]
  stages: CreateV1.Stage[]
  academicPeriods: CreateV1.AcademicPeriod[]
}

export default function useCreateInstitution() {
  const query = useMutation<AxiosResponse<void>, ClientError, CreateInstitutionVars>({
    mutationFn: data => {
      return org.pricipal.organizations.create(data)
    },
  })

  return query
}
