import { useApp } from '@/modules/app/state/useApp'
import { lms } from '@/services/client'
import type { ClientError } from '@educabot/cloud-client'
import type { GetInfoResponse } from '@educabot/cloud-client/lib/lms/user/info/get/get'
import { useQuery } from '@tanstack/react-query'
import type { AxiosResponse } from 'axios'
import { useEffect } from 'react'

export default function useUserInfo() {
  const { setUser } = useApp()

  const query = useQuery<AxiosResponse<GetInfoResponse>, ClientError>({
    queryKey: ['user_info'],
    queryFn: async () => {
      return lms.user.info.getV3()
    },
  })

  useEffect(() => {
    if (query.isSuccess) {
      setUser(query.data.data.description)
    }
  }, [query.status])

  return query
}
