import { createTheme } from '@mui/material'
import { primary, secondary, text } from './color'
import { overrides } from './overrides'

const theme = createTheme({
  ...overrides,
  typography: {
    fontFamily: 'Lato, sans-serif',
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
  palette: {
    mode: 'light',
    divider: '#d0d0d0',
    background: {
      default: '#f0f0f0',
      paper: '#FAFAFA',
    },
    text: {
      primary: text.primary,
      secondary: text.secondary,
    },
    primary: {
      light: primary.light,
      main: primary.main,
      dark: primary.dark,
    },
    secondary: {
      light: secondary.light,
      main: secondary.main,
      dark: secondary.dark,
    },
    error: {
      main: '#ff2c3d',
    },
    warning: {
      main: '#F45C3A',
    },
    info: {
      main: '#3e96d1',
    },
    success: {
      main: '#019f34',
    },
  },
})

export default theme
