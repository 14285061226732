import { useAuth } from '@/modules/auth/state/useAuth'
import { useMsal } from '@azure/msal-react'
import { Box } from '@mui/system'
import { useEffect } from 'react'
import useSignupWithMicrosoft from '../queries/useSignupWithMicrosoft'
import AuthButton from './authButton'

interface MicrosoftButtonProps {
  onError: (err: string) => void
}

interface MsalError {
  errorCode: string
  message: string
}

//TODO add i18n
const MicrosoftButton = ({ onError }: MicrosoftButtonProps) => {
  const msal = useMsal()
  const { login } = useAuth()
  const signupWithMicrosoft = useSignupWithMicrosoft()

  const handleOnClick = async () => {
    try {
      const result = await msal.instance.acquireTokenPopup({
        scopes: ['User.Read'],
      })

      if (result.idToken) signupWithMicrosoft.mutate(result.idToken)
    } catch (err) {
      const error = err as MsalError
      if (error.errorCode === 'user_cancelled') return
      onError('No pudimos validar tu identidad, por favor intenta nuevamente.')
    }
  }

  useEffect(() => {
    if (signupWithMicrosoft.isSuccess) {
      const { data } = signupWithMicrosoft.data
      login(data.description.token)
    }

    if (signupWithMicrosoft.isError) {
      onError(signupWithMicrosoft.error.message)
    }
  }, [signupWithMicrosoft.status])

  return (
    <Box onClick={handleOnClick}>
      <AuthButton type="microsoft" />
    </Box>
  )
}

export default MicrosoftButton
