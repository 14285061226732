import { capitalize } from '@/utils/text'
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Typography,
} from '@mui/material'
import { Controller } from 'react-hook-form'
import type { Control, FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import type { InstitutionModalInputs, StageSelector } from '../../types/institution'

interface StepThreeProps {
  stages: StageSelector[]
  control: Control<InstitutionModalInputs>
  errors: FieldErrors<InstitutionModalInputs>
}

export default function StepThree({ stages, control, errors }: StepThreeProps) {
  const { t } = useTranslation()
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box mb={4}>
        <Typography mb={1} variant="h5">
          Indicá qué niveles educativos se dictan en tu institución.
        </Typography>
        <Typography fontSize={t => t.typography.pxToRem(16)} color={'text.secondary'}>
          Selecciona un nivel.
        </Typography>
      </Box>
      <FormControl required error={!!errors.stages?.message} component="fieldset" sx={{ ml: 2 }}>
        <FormLabel sx={{ mb: 1 }}>{capitalize(t('levels', { ns: 'common' }))}</FormLabel>
        <Controller
          name="stages"
          control={control}
          rules={{
            validate: val => val.some(v => v.checked) || 'Debes seleccionar al menos una opción',
          }}
          render={({ field }) => {
            return (
              <>
                {stages.map(stage => {
                  return (
                    <FormControlLabel
                      key={stage.name}
                      label={capitalize(t(`levels.${stage.name}`, { ns: 'glossary' }))}
                      control={
                        <Checkbox
                          checked={field.value.find(s => s.name === stage.name)?.checked}
                          onChange={e => {
                            const updatedStages = field.value.map(s =>
                              s.name === stage.name ? { ...s, checked: e.target.checked } : s,
                            )
                            field.onChange(updatedStages)
                          }}
                        />
                      }
                    />
                  )
                })}
              </>
            )
          }}
        />
        <FormHelperText sx={{ ml: 0, mt: 1 }}>{errors.stages?.message}</FormHelperText>
      </FormControl>
    </Box>
  )
}
